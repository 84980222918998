import moment from 'moment';
import { CURRENCY } from '../config';
import { useMediaQuery, useTheme } from '@mui/material';

export const downloadPdf = (url: string) => {
  window.open(url, '_blank');
};

export const useIsSmallScreen = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('sm'));
};

export const useIsMediumScreen = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('md'));
};

export const getPriceFormatCodeToSymbol = (
  amount: number,
  currency?: string
): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: CURRENCY,
    // currency: currency || 'LKR',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(amount);
};

type FontSizeResult = {
  fontSize: number;
  label: string;
  fontWeight?: string;
};

export const calculateFontSize = (width: number): FontSizeResult => {
  if (width === 217) return { fontSize: 10, label: '58mm' };
  if (width === 300) return { fontSize: 12, label: '80mm', fontWeight: 'bold'};
  if (width === 557) return { fontSize: 14, label: 'A5' };
  if (width === 790) return { fontSize: 16, label: 'A4' };
  return { fontSize: 12, label: 'Default' };
};

export const getPriceFormat = (amount: number, currency?: string): string => {
  const numericAmount =
    typeof amount === 'string' ? parseFloat(amount) : amount;
  const formattedAmount = numericAmount
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if (currency) {
    return `${currency.toUpperCase()} ${formattedAmount}`;
  }

  return formattedAmount;
};

export const getPriceFormatWithoutCurrency = (amount: number): string => {
  return amount.toLocaleString('en-US', { minimumFractionDigits: 2 });
};

export const getPriceFormatWithCurrencyCode = (
  amount: number,
  curr_code: string
): string => {
  const amountFormat = amount.toLocaleString('en-US', {
    minimumFractionDigits: 2,
  });
  const formatter = `${curr_code} ${amountFormat}`;
  return formatter;
};

export const formatDate = (date: Date): string => {
  return moment(date).format('DD-MM-YYYY');
};

export const formatDateTime = (date: Date): string => {
  return moment(date).format('DD-MM-YYYY hh:mm A');
};

export const formatDateMonthYear = (date: Date): string => {
  return moment(date).format('MMMM YYYY');
};

export const formatTimeToUtc = (date: string) => {
  if (!date.includes('Z')) {
    date = date + 'Z';
  }
  return moment(date).format('DD-MM-YYYY hh:mm A');
};

const characters =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export const generateString = (length: number) => {
  let result = ' ';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const uploadImage = async (file: File): Promise<string | null> => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await fetch(
      'https://allespos.centralindia.cloudapp.azure.com/AllesposApi/api/v1/Utility/upload',
      {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token') || ''}`, // Include the authentication token
        },
      }
    );

    if (response.ok) {
      const responseData = await response.json();

      return responseData.data;
    } else {
      throw new Error('Error uploading image');
    }
  } catch (error) {
    throw new Error('Error uploading image');
  }
};

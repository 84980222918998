import React, { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import {
  Backdrop,
  Badge,
  Button,
  Card,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { appColors } from '../../theme/appColors';

import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import Summary from './components/Summary';
import SalesActionLeft from './components/SalesActionLeft';
import ItemCard from './components/ItemCard';
import SalesRight from './components/SalesRight';
import useSales from './useSales';
import FinalSummary from './components/FinalSummary';
import AddMobile from './components/AddMobile';
import Person3Icon from '@mui/icons-material/Person3';
import { useSelector } from 'react-redux';
import { addCustomer } from './store/BasketSelector';
import { useTranslation } from 'react-i18next';
import AnimatedPage from '../../layouts/AnimatedPage';
import {
  useGetAllPendingSalesQuery,
  useGetSalesDetailsByOrderIdQuery,
  useGetUserQuery,
} from '../posApi';
import {
  IUserInfo,
  SalesItem,
  SalesReceipt,
} from '../../core/Interface/api.interface';
import { useAppDispatch } from '../../store/hooks';
import {
  addFinalSummaryState,
  addItem,
  BasketItem,
  removeBasket,
} from './store/BasketSlice';
import { generateString } from '../../core/utils';
import { theme } from '../../theme';

const RestaurantSales = () => {
  const { calculateSummary, selectItem, paymentStatus } = useSales();
  const [openAddMobile, setOpenAddMobile] = React.useState(false);
  const selectMobile = useSelector(addCustomer);
  const { t } = useTranslation(['sales']);
  const dispatch = useAppDispatch();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { data: companyData } = useGetUserQuery();
  const [orderId, setOrderId] = useState<string>('');
  const { data, isLoading } = useGetAllPendingSalesQuery();

  const viewSaleDetails = (id: string) => {
    if (orderId === id) {
      setOrderId(id + '_' + generateString(5));
    } else {
      setOrderId(id);
    }
  };

  const {
    data: salesDetailsData,
    isLoading: salesDetailsLoading,
    isFetching,
  } = useGetSalesDetailsByOrderIdQuery(orderId.split('_')[0], {
    skip: !orderId,
  });
  const [salesDetails, setSalesDetails] = React.useState<SalesReceipt>();
  useEffect(() => {
    if (salesDetailsData && salesDetailsData.data) {
      const salesReceipt = salesDetailsData.data as SalesReceipt;
      console.log('salesDetailsData');
      // Map SalesItem to BasketItem and dispatch addItem for each
      salesReceipt.items.forEach((item) => {
        // Dispatch the addItem action
      });
      // Optionally update other state or dispatch actions
      setSalesDetails(salesReceipt);
    }
  }, [salesDetailsData, orderId]);

  const [salesList, setSalesList] = useState<SalesReceipt | null>(null);

  useEffect(() => {
    if (salesDetailsData?.data) {
      setSalesList(salesDetailsData.data as SalesReceipt);
    }
  }, [salesDetailsData?.data]);

  const displaySalesList = salesList ?? {
    discount: 0,
    subTotal: 0,
    total: 0,
  };

  const clearSalesItems = () => {
    setSalesList(null);
  };

  const currencyCode = useMemo(() => {
    if (companyData?.data) {
      const userData = companyData.data as IUserInfo;
      if (userData.company.currencyCode) {
        return userData.company.currencyCode;
      }
    }
    return '';
  }, [companyData]);

  //!

  interface Bill {
    id: string;
    orderId: string;
  }

  const getAllSalesList = useMemo(() => {
    return data?.data as Bill[];
  }, [data?.data]);

  // Example of how to add a new bill with ID
  // const addBill = (newBillNumber: string) => {
  //   setBills((prevBills) => [
  //     ...prevBills,
  //     { id: prevBills.length + 1, billNumber: newBillNumber },
  //   ]);
  // };

  const [selectedBill, setSelectedBill] = React.useState<Bill | undefined>();

  const handleBillClick = (bill: Bill) => {
    if (selectItem && Object.keys(selectItem).length > 0) {
      console.log('in');
      dispatch(removeBasket());
    }
    console.log('paymentStatus', selectItem);
    setSelectedBill(bill);
    viewSaleDetails(bill.id);
    if (!paymentStatus) {
      dispatch(addFinalSummaryState({ finalSummaryStare: true }));
      return;
    }
  };

  const [viewportWidth, setViewportWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setViewportWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <AnimatedPage>
      <div style={{}}>
        {/* <SalesMain> */}
        <Box
          data-testid="Res"
          sx={{ px: 2, py: 2, width: `${viewportWidth}px` }}
        >
          <Grid container>
            <Grid
              item
              lg={4}
              md={4}
              sm={12}
              sx={{
                height: '80px',
                minHeight: '100%',
              }}
              xs={12}
            >
              <Box>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    padding: '0px 10px 0px 0px',

                    alignItems: 'center',
                  }}
                >
                  <Grid
                    item
                    sx={{ display: 'flex', alignItems: 'center' }}
                    xs={6}
                  >
                    <Badge
                      badgeContent={selectItem.length}
                      color="secondary"
                      sx={{
                        '& .MuiBadge-badge': {
                          right: 2,
                          top: 11,
                          backgroundColor: appColors.orange[50],
                        },
                      }}
                    >
                      <ShoppingBasketIcon
                        sx={{
                          fontSize: 32,
                          marginRight: '6px',
                        }}
                      />
                    </Badge>
                    <Typography
                      sx={{
                        fontSize: '1.3rem',
                        fontWeight: 'bold',
                        pl: '12px',
                        pt: '4px',
                      }}
                    >
                      {t('sales:order')}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    display="flex"
                    flexDirection="row"
                    item
                    justifyContent="flex-end"
                    xs={6}
                  >
                    <Button
                      onClick={() => setOpenAddMobile(true)}
                      startIcon={
                        selectMobile && (
                          <Person3Icon
                            sx={{ color: appColors.carmineRed[60] }}
                          />
                        )
                      }
                      sx={{ backgroundColor: appColors.blueGreyishLight[40] }}
                      variant="outlined"
                    >
                      {t('sales:customer')}
                    </Button>
                    {openAddMobile && (
                      <AddMobile
                        handleCloseDialog={() => setOpenAddMobile(false)}
                        openModel={openAddMobile}
                        title={`Add Customer`}
                        customers=""
                      />
                    )}
                  </Grid>
                </Grid>
              </Box>
              <Box data-testid="itemCard">
                <ItemCard salesItems={salesList?.items ?? []} />
              </Box>

              <Box
                sx={{
                  position: 'relative',

                  left: 'auto',
                  right: 'auto',
                  zIndex: 'auto',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  mt: '20px',
                }}
              >
                {!paymentStatus && (
                  <Summary
                    discount={calculateSummary.discount}
                    subtotal={calculateSummary.subTotal}
                    total={calculateSummary.total}
                    currencyCode={currencyCode}
                  />
                )}
                {paymentStatus && (
                  <Summary
                    discount={displaySalesList.discount}
                    subtotal={displaySalesList.subTotal}
                    total={displaySalesList.total}
                    currencyCode={currencyCode}
                  />
                )}
                <Grid sx={{ mt: '20px' }} />
                <SalesActionLeft
                  onClear={clearSalesItems}
                  total={salesList?.total ?? 0}
                  saveResSalesPayload={{
                    received: 100,
                    saleId: orderId.split('_')[0],
                  }}
                />
              </Box>
            </Grid>
            <Grid
              item
              lg={8}
              md={8}
              sm={8}
              xs={12}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  overflowX: 'auto',
                  //  mb: 2,
                  borderBottom: '1px solid',
                  borderColor: appColors.darkGray[20],
                }}
              >
                {getAllSalesList &&
                  getAllSalesList.map((bill, index) => (
                    <Card
                      key={index}
                      onClick={() => handleBillClick(bill)}
                      sx={{
                        cursor: 'pointer',
                        ml: 1,
                        mr: 1,
                        mb: 1.7,
                        px: 2,
                        py: 1,
                        width: '150px',
                        flexShrink: 0,
                        backgroundColor:
                          selectedBill === bill
                            ? appColors.darkGreen[90]
                            : appColors.darkGreen[20],
                        border: `1px solid ${
                          selectedBill === bill
                            ? appColors.darkGreen[90]
                            : appColors.darkGray[20]
                        }`,
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: selectedBill === bill ? 700 : 500 }}
                      >
                        {bill.orderId}
                      </Typography>
                    </Card>
                  ))}
              </Box>
              <Backdrop
                open={salesDetailsLoading || isFetching}
                sx={{
                  color: '#fff',
                  zIndex: (theme1) => theme1.zIndex.drawer + 1,
                }}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              {paymentStatus &&
                !salesDetailsLoading &&
                !isFetching &&
                salesDetailsData && (
                  <FinalSummary
                    subtotal={salesDetails?.subTotal ?? 0}
                    discount={salesDetails?.discount ?? 0}
                    total={salesDetails?.total ?? 0}
                  />
                )}
              {!paymentStatus && <SalesRight />}
            </Grid>
          </Grid>
        </Box>
        {/* </SalesMain> */}
      </div>
    </AnimatedPage>
  );
};

export default RestaurantSales;

import { Box, Divider, Grid, Typography } from '@mui/material';
import * as React from 'react';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { appColors } from '../../../theme/appColors';
import { useSelector } from 'react-redux';
import {
  selectBasketItems,
  selectMaxDiscountAmount,
} from '../store/BasketSelector';
import { useAppDispatch } from '../../../store/hooks';
import { BasketItem, removeItem } from '../store/BasketSlice';
import { getPriceFormatWithoutCurrency } from '../../../core/utils';
import AddItemCount from './AddItemCount';
import { IAddCount } from '../../../core/Interface/property.interface';
import { SalesItem } from '../../../core/Interface/api.interface';
interface ItemCardProps {
  salesItems: SalesItem[];
}

const ItemCard = ({ salesItems }: ItemCardProps) => {
  const dispatch = useAppDispatch();
  const selectItem = useSelector(selectBasketItems);
  const [boxHeight, setBoxHeight] = React.useState<number | undefined>(
    undefined
  );
  const [openProductCount, setOpenProductCount] = React.useState(false);
  const [itemCountProb, setItemCountProb] = React.useState<IAddCount>();

  const maxDiscountAmount = useSelector(selectMaxDiscountAmount);

  React.useEffect(() => {
    const calculateHeight = () => {
      const windowHeight = window.innerHeight;
      const adjustedHeight = windowHeight - 400;
      setBoxHeight(adjustedHeight);
    };

    calculateHeight();

    window.addEventListener('resize', calculateHeight);

    return () => {
      window.removeEventListener('resize', calculateHeight);
    };
  }, []);

  return (
    <Box
      sx={{
        pt: 2,
      }}
    >
      <Box
        sx={{
          height: boxHeight ? `${boxHeight}px` : 'auto',

          overflowY: 'scroll',
          pr: 2,
          pt: 2,
          pl: 2,
          backgroundColor: appColors.blueGreyishLight[20],
          '::-webkit-scrollbar-thumb': {
            background: appColors.blueGreyishLight[20],
          },
          '::-webkit-scrollbar-track': {
            background: appColors.blueGreyishLight[20],
          },
        }}
      >
        <Grid
          alignItems="center"
          container
          justifyContent="space-between"
          spacing={1}
        >
          {salesItems &&
            salesItems.map((item) => (
              <>
                <LeftGrid
                  addCount={(itemCount) => {
                    setItemCountProb(itemCount);
                    setOpenProductCount(true);
                  }}
                  id={item.productId}
                  code={item.productName}
                  count={item.qty}
                  discount={item.discount}
                  discountAsPercentage={item.isDiscountPercentage}
                  text={item.productName}
                  unitPrice={item.unitPrice}
                />
                <RightGridForSales
                  amount={item.unitPrice * item.qty}
                  clearItem={(id) => {}}
                  id={item.productId}
                  code={item.productName}
                  product={item}
                />
                <Divider
                  flexItem
                  orientation="horizontal"
                  sx={{
                    borderColor: appColors.darkGreen[90],
                    width: '100%',
                  }}
                />
              </>
            ))}
          {selectItem
            .slice()
            .reverse()
            .map((item) => (
              <>
                <LeftGrid
                  addCount={(itemCount) => {
                    setItemCountProb(itemCount);
                    setOpenProductCount(true);
                  }}
                  id={item.id}
                  code={item.code}
                  count={item.count}
                  discount={item.defaultDiscountAmount}
                  discountAsPercentage={item.isDiscountPercentage}
                  text={item.name}
                  unitPrice={item.unitPrice}
                />
                <RightGrid
                  amount={item.unitPrice * item.count}
                  clearItem={(id) => {
                    dispatch(removeItem(id));
                  }}
                  id={item.id}
                  code={item.code}
                  product={item}
                />
                <Divider
                  flexItem
                  orientation="horizontal"
                  sx={{
                    borderColor: appColors.darkGreen[90],
                    width: '100%',
                  }}
                />
              </>
            ))}
        </Grid>
      </Box>
      {openProductCount && (
        <AddItemCount
          handleCloseDialog={() => setOpenProductCount(false)}
          item={itemCountProb}
          openModel={openProductCount}
          title={`Update product count`}
          maxDiscountAmount={maxDiscountAmount}
        />
      )}
    </Box>
  );
};

export default React.memo(ItemCard);

// eslint-disable-next-line react/no-multi-comp
const LeftGrid = ({
  code,
  text,
  count,
  discount,
  discountAsPercentage,
  addCount,
  unitPrice,
  id,
}: {
  code: string;
  text: string;
  count: number;
  discount?: number;
  discountAsPercentage?: boolean;
  unitPrice?: number;
  id: string;
  addCount: (itemCount: IAddCount) => void;
}) => {
  return (
    <Grid
      alignItems="center"
      container
      display="flex"
      flexDirection="row"
      item
      key={count}
      xs={5}
    >
      <Box
        onClick={() => {
          addCount({
            code,
            count,
            discountValue: discount,
            isDiscountPercentage: discountAsPercentage,
            unitPrice,
            id,
          });
        }}
        sx={{
          backgroundColor: appColors.persianBlue[100],
          height: '10%',
          minWidth: '10%',
          px: 0.5,
          display: 'flex',
          justifyContent: 'center',
          cursor: 'pointer',
          borderRadius: 1,
        }}
      >
        <Typography
          sx={{
            color: appColors.white,
            fontWeight: 500,
          }}
        >
          {count}
        </Typography>
      </Box>
      <Typography
        onClick={() => {
          addCount({
            code,
            count,
            discountValue: discount,
            isDiscountPercentage: discountAsPercentage,
            unitPrice,
            id,
          });
        }}
        sx={{
          px: 1,
          py: 1,
          mr: 1,
          wordWrap: 'break-word',
          // color: appColors.white,
          fontWeight: 500,
          cursor: 'pointer',
        }}
      >
        {text}
      </Typography>
    </Grid>
  );
};

// eslint-disable-next-line react/no-multi-comp
const RightGrid = ({
  amount,
  code,
  product,
  clearItem,
  id,
  ...rest
}: {
  amount: number;
  code: string;
  product: BasketItem;
  id: string;
  clearItem: (code: string) => void;
}) => {
  let productPrice = product.unitPrice * product.count;
  if (product.defaultDiscountAmount) {
    if (product.isDiscountPercentage) {
      productPrice =
        (product.unitPrice -
          (product.unitPrice * product.defaultDiscountAmount) / 100) *
        product.count;
    } else {
      productPrice =
        product.unitPrice * product.count -
        product.defaultDiscountAmount * product.count;
    }
  }
  return (
    <Grid
      container
      display="flex"
      flexDirection="row"
      item
      justifyContent="flex-end"
      xs={7}
      {...rest}
      key={code}
    >
      <Typography
        sx={{
          fontWeight: 600,
          color: appColors.reflexBlue[50],
          pr: 2,
          fontSize: 12,
          width: 100,
        }}
      >
        Up {getPriceFormatWithoutCurrency(product.unitPrice)}
        <br></br>
        {product.defaultDiscountAmount && (
          <>
            Dis {product.defaultDiscountAmount}{' '}
            {product.isDiscountPercentage ? '%' : ''}
          </>
        )}
      </Typography>

      <Typography
        sx={{
          textAlign: 'end',
          fontWeight: 600,
          color: appColors.reflexBlue[50],
          width: 100,
        }}
      >
        {getPriceFormatWithoutCurrency(productPrice)}
      </Typography>
      <DeleteOutlinedIcon
        onClick={() => clearItem(id)}
        sx={{
          color: appColors.carmineRed[30],
          marginRight: '3px',
          marginLeft: '20px',
          cursor: 'pointer',
        }}
      />
    </Grid>
  );
};

const RightGridForSales = ({
  amount,
  code,
  product,
  clearItem,
  id,
  ...rest
}: {
  amount: number;
  code: string;
  product: SalesItem;
  id: string;
  clearItem: (code: string) => void;
}) => {
  let productPrice = product.unitPrice * product.qty;

  return (
    <Grid
      container
      display="flex"
      flexDirection="row"
      item
      justifyContent="flex-end"
      xs={7}
      {...rest}
      key={code}
    >
      <Typography
        sx={{
          fontWeight: 600,
          color: appColors.reflexBlue[50],
          pr: 2,
          fontSize: 12,
          width: 100,
        }}
      >
        Up {getPriceFormatWithoutCurrency(product.unitPrice)}
        <br></br>
      </Typography>

      <Typography
        sx={{
          textAlign: 'end',
          fontWeight: 600,
          color: appColors.reflexBlue[50],
          width: 100,
        }}
      >
        {getPriceFormatWithoutCurrency(productPrice)}
      </Typography>
      <DeleteOutlinedIcon
        onClick={() => clearItem(id)}
        sx={{
          color: appColors.carmineRed[30],
          marginRight: '3px',
          marginLeft: '20px',
          cursor: 'pointer',
        }}
      />
    </Grid>
  );
};

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { BasketItem, BasketState } from './BasketSlice';
import {
  IAddCustomer,
  IBasketState,
  IDiscount,
} from '../../../core/Interface/property.interface';

export const selectBasket = (state: RootState) =>
  state.restaurantBasket as BasketState;

export const selectBasketItems = createSelector(
  selectBasket,
  (basket) => basket.items as BasketItem[]
);

export const selectDiscount = createSelector(
  selectBasket,
  (basket) => basket as IDiscount
);

export const finalSummaryStare = createSelector(
  selectBasket,
  (basket) => (basket as IBasketState).finalSummaryStare
);

export const addCustomer = createSelector(
  selectBasket,
  (basket) => basket as IAddCustomer
);
export const selectPaymentMethod = createSelector(
  selectBasket,
  (basket) => basket.paymentMethod
);
export const selectMaxDiscountAmount = createSelector(
  selectBasketItems,
  (items) => {
    let maxDiscountAmount = 0;
    for (const item of items) {
      if (
        item.maxDiscountAmount &&
        item.maxDiscountAmount > maxDiscountAmount
      ) {
        maxDiscountAmount = item.maxDiscountAmount;
      }
    }
    return maxDiscountAmount;
  }
);

import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  CardMedia,
  Stack,
  TextField,
} from '@mui/material';
import * as React from 'react';
import Tab from '@mui/material/Tab';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useGetAllProductQuery } from '../../posApi';
import TopInfoSkeleton from '../../Dashboard/TopInfoSkeleton';
import { useMemo } from 'react';
import { IProduct, IProductData } from '../../../core/Interface/api.interface';
import { appColors } from '../../../theme/appColors';
import { useAppDispatch } from '../../../store/hooks';
import { BasketItem, addItem } from '../store/BasketSlice';
import { getPriceFormatWithoutCurrency } from '../../../core/utils';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useTranslation } from 'react-i18next';
import AlertPopup from './Kgpopup';
import useScanDetection from 'use-scan-detection-react18';

const SalesRight = () => {
  const { t } = useTranslation(['sales']);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data, isLoading } = useGetAllProductQuery({
    pageIndex: 0,
    pageSize: 1000,
    searchText: '',
  });

  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const productDataMemo = useMemo(() => {
    return data?.data as IProduct;
  }, [data?.data]);

  const [selectedProduct, setSelectedProduct] =
    React.useState<IProductData | null>(null);

  const addItemToCard = (item: IProductData) => {
    if (item.isKgOrLitre) {
      setSelectedProduct(item);
      setOpenShowPopup(true);
    } else {
      const basketItem: BasketItem = { ...item, count: 1 };
      dispatch(addItem(basketItem));

      focusSearchInput();
    }
  };
  const groupDataByCategory = () => {
    const groupedData: Record<string, IProductData[]> = {};

    if (!isLoading && productDataMemo && productDataMemo.records) {
      productDataMemo.records.forEach((re) => {
        const categoryName = re.categoryName;
        if (!groupedData[categoryName]) {
          groupedData[categoryName] = [];
        }
        groupedData[categoryName].push(re);
      });
    }

    return groupedData;
  };

  const groupedData = useMemo(() => groupDataByCategory(), [productDataMemo]);
  const uniqueCategoryNames = useMemo(() => {
    return Object.keys(groupedData);
  }, [groupedData]);

  const searchInputRef = React.useRef<HTMLInputElement>(null);

  const focusSearchInput = () => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };

  React.useEffect(() => {
    focusSearchInput();
  }, []);

  React.useEffect(() => {
    focusSearchInput();
  }, [selectedProduct]);

  const [boxHeight, setBoxHeight] = React.useState<number | undefined>(
    undefined
  );
  React.useEffect(() => {
    const calculateHeight = () => {
      const windowHeight = window.innerHeight;
      const adjustedHeight = windowHeight - 200;
      setBoxHeight(adjustedHeight);
    };

    calculateHeight();

    window.addEventListener('resize', calculateHeight);

    return () => {
      window.removeEventListener('resize', calculateHeight);
    };
  }, []);

  const [boxWidth, setBoxWidth] = React.useState<number | undefined>(undefined);
  React.useEffect(() => {
    const calculateWidth = () => {
      const windowsWidth = window.innerWidth;
      const adjustedWeight = windowsWidth - 466;
      setBoxWidth(adjustedWeight);
    };
    calculateWidth();
    window.addEventListener('resize', calculateWidth);
    return () => {
      window.removeEventListener('resize', calculateWidth);
    };
  }, []);

  const [searchQuery, setSearchQuery] = React.useState('');

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQuery(event.target.value);
  };

  const filteredProducts = useMemo(() => {
    const query = searchQuery.toLowerCase().trim();
    return productDataMemo?.records.filter((re) => {
      const productName = re.name.toLowerCase();
      const productCode = re.code.toLowerCase();
      return productName.includes(query) || productCode.includes(query);
    });
  }, [productDataMemo, searchQuery]);

  const [openShowPopup, setOpenShowPopup] = React.useState(false);

  const handleClosePopup = () => {
    setOpenShowPopup(false);
  };

  const handleAdd = (calculatedValue: number | null, inputCount: number) => {
    if (selectedProduct && calculatedValue !== null) {
      const basketItem: BasketItem = {
        ...selectedProduct,
        count: inputCount,
      };
      dispatch(addItem(basketItem));
      setOpenShowPopup(false);
    }
  };

  useScanDetection({
    onComplete: (code) => {
      var ret = code.replace('Shift', '');
      var products = productDataMemo?.records.filter((re) => {
        return re.code.includes(ret);
      });
      if (products.length === 1) {
        const basketItem: BasketItem = { ...products[0], count: 1 };
        dispatch(addItem(basketItem));
        setSearchQuery('');
      }
    },
  });

  return (
    <Box sx={{ pt: 0, maxWidth: '96vw' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pr: 1,
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          gap={3}
          onClick={() => navigate('/dashboard')}
          sx={{ cursor: 'pointer' }}
        >
          <ArrowBackIosNewIcon />
          <Typography
            sx={{ fontWeight: 600 }}
            variant="h6"
          >
            {t('sales:sales')}
          </Typography>
        </Stack>

        <TextField
          autoComplete="off"
          fullWidth
          inputRef={searchInputRef}
          onChange={handleSearchInputChange}
          placeholder="Search Name Or Code"
          size="small"
          sx={{
            width: 300,
            mr: 5,
            mb: 0,
            py: 2,
            marginTop: '20px',
            '& .MuiFormLabel-root.MuiInputLabel-root': {
              color: 'appColors.darkGray[20]',
            },
          }}
          value={searchQuery}
          variant="outlined"
          autoFocus
        />
      </Box>
      <Box sx={{}}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              aria-label="lab API tabs example"
              onChange={handleChange}
              scrollButtons="auto"
              sx={{
                minWidth: '200px',
                width: '100%',
                '& .Mui-selected.MuiTab-root': {
                  color: appColors.darkGray[90],
                  fontSize: 20,
                  fontWeight: 700,
                },
                '& .MuiTabs-indicator ': {
                  backgroundColor: appColors.darkGray[90],
                },
                '& .MuiTab-root': {
                  color: appColors.darkGray[40],
                  fontSize: 15,
                  fontWeight: 700,
                },
              }}
              variant="scrollable"
            >
              <Tab
                label="ALL"
                value="1"
              />
              {uniqueCategoryNames
                .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                .map((categoryName, index) => (
                  <Tab
                    key={index}
                    label={categoryName}
                    value={String(index + 2)}
                  />
                ))}
            </TabList>
          </Box>
          <TabPanel
            sx={{
              maxHeight: boxHeight ? `${boxHeight}px` : 'auto',
              overflowY: 'auto',
            }}
            value="1"
          >
            <Grid
              container
              spacing={2}
            >
              {!isLoading &&
                productDataMemo &&
                productDataMemo.records &&
                (searchQuery ? filteredProducts : productDataMemo.records).map(
                  (re, index) =>
                    re.isEnabled && (
                      <Grid
                        item
                        key={index}
                        lg={3}
                        md={4}
                        sm={6}
                        xs={12}
                      >
                        <Card
                          onClick={() => addItemToCard(re)}
                          sx={{ p: 0, m: 0, cursor: 'pointer' }}
                          variant="outlined"
                        >
                          {re.imageUrl && (
                            <CardMedia
                              component="img"
                              image={re.imageUrl}
                              sx={{
                                height: '150px',
                                margin: '0',
                                width: '100%',
                              }}
                            />
                          )}

                          <CardContent
                            sx={{
                              backgroundColor: appColors.blueGreyishLight[10],
                            }}
                          >
                            <Typography
                              sx={{ pb: 1 }}
                              variant="h6"
                            >
                              {re.code}
                            </Typography>
                            <Typography
                              sx={{ pb: 1, fontWeight: 700 }}
                              variant="h6"
                            >
                              {re.name?.length > 13
                                ? `${re.name.substring(0, 13)}...`
                                : re.name}
                            </Typography>
                            <Typography
                              sx={{ color: appColors.carmineRed[100], pb: 1 }}
                              variant="h6"
                            >
                              {getPriceFormatWithoutCurrency(re.unitPrice)}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    )
                )}
              {isLoading &&
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => (
                  <Grid
                    item
                    key={i}
                    xs={3}
                  >
                    <TopInfoSkeleton data-testid="top--skeleton" />
                  </Grid>
                ))}
            </Grid>
          </TabPanel>
          {uniqueCategoryNames.map((categoryName, index) => (
            <TabPanel
              key={index}
              sx={{
                maxHeight: boxHeight ? `${boxHeight}px` : 'auto',
                overflowY: 'auto',
              }}
              value={String(index + 2)}
            >
              <div>
                <Grid
                  container
                  spacing={2}
                >
                  {(searchQuery
                    ? filteredProducts?.filter(
                        (re) => re.categoryName === categoryName
                      )
                    : groupedData[categoryName]
                  )?.map(
                    (re, productIndex) =>
                      re.isEnabled && (
                        <Grid
                          item
                          key={index}
                          lg={3}
                          md={4}
                          sm={6}
                          xs={12}
                        >
                          <Card
                            onClick={() => addItemToCard(re)}
                            sx={{ p: 0, m: 0, cursor: 'pointer' }}
                            variant="outlined"
                          >
                            {re.imageUrl && (
                              <CardMedia
                                component="img"
                                image={re.imageUrl}
                                sx={{
                                  height: '150px',
                                  margin: '0',
                                  width: '100%',
                                }}
                              />
                            )}

                            <CardContent>
                              <Typography
                                sx={{ pb: 1 }}
                                variant="h6"
                              >
                                {re.code}
                              </Typography>
                              <Typography
                                sx={{ pb: 1, fontWeight: 700 }}
                                variant="h6"
                              >
                                {re.name}
                              </Typography>
                              <Typography
                                sx={{ color: appColors.carmineRed[100], pb: 1 }}
                                variant="h6"
                              >
                                {getPriceFormatWithoutCurrency(re.unitPrice)}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      )
                  )}
                </Grid>
              </div>
            </TabPanel>
          ))}
        </TabContext>
      </Box>

      {selectedProduct && selectedProduct.isKgOrLitre && (
        <AlertPopup
          key={selectedProduct.id}
          open={openShowPopup}
          handleCloseAlert={handleClosePopup}
          unitPrice={selectedProduct.unitPrice ?? 0}
          handleAdd={handleAdd}
        />
      )}
    </Box>
  );
};

export default React.memo(SalesRight);

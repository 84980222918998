import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  useMediaQuery,
} from '@mui/material';
import FieldHeader from '../../../components/FieldHeader';
import { theme } from '../../../theme';
import { useFormik } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IDiscount } from '../../../core/Interface/property.interface';
import { useAppDispatch } from '../../../store/hooks';
import { addDiscount } from '../store/BasketSlice';
import { useSelector } from 'react-redux';
import { selectDiscount } from '../store/BasketSelector';

interface IDiscountProb {
  openModel?: boolean;
  title: string;
  handleCloseDialog: (close: boolean) => void;
}

const SalesDiscount = ({
  title,
  openModel = false,
  handleCloseDialog,
}: IDiscountProb) => {
  const { t } = useTranslation(['common']);
  const [open] = React.useState(openModel);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useAppDispatch();
  const selectDis = useSelector(selectDiscount);

  const handleClose = () => {
    handleCloseDialog(false);
  };

  const formik = useFormik({
    initialValues: {
      discount: selectDis.discount,
      discountAsPercentage: selectDis.discountAsPercentage,
    },
    onSubmit: async (values) => {
     
      const discount: IDiscount = values;
      dispatch(addDiscount(discount));
      handleClose();
    },
  });

  const formValid = useMemo(() => {
    return formik.values.discount === undefined ? false : true;
  }, [formik]);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Dialog
          aria-describedby="alert-dialog-description"
          classes={{ paper: 'inherit' }}
          fullScreen={isMobile}
          fullWidth={isMobile}
          maxWidth="lg"
          open={open}
        >
          <DialogTitle sx={{ fontWeight: 600, p: 4 }}>{title}</DialogTitle>
          <DialogContent sx={{ width: isMobile ? 'auto' : '40vw', p: 4 }}>
            <Box>
              <Grid container>
                <GridItem>
                  <FieldHeader title={'Discount'} />
                  <TextField
                    autoComplete="off"
                    placeholder="Discount"
                    size="small"
                    {...formik.getFieldProps('discount')}
                    InputProps={{ inputProps: { min: 1 } }}
                    sx={{ height: `${theme.spacing(5)}`, width: '100%' }}
                    type="number"
                  />
                </GridItem>
                <GridItem>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.discountAsPercentage}
                        {...formik.getFieldProps('discountAsPercentage')}
                      />
                    }
                    label="Is Percentage (%)"
                    sx={{ pt: 3, pl: 2 }}
                  />
                </GridItem>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions sx={{ paddingRight: '24px', p: 4, py: 3, gap: 2 }}>
            <Button
              onClick={handleClose}
              sx={{ width: 200, height: 42 }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              autoFocus
              disabled={!formValid}
              onClick={() => {
                formik.handleSubmit();
              }}
              sx={{ width: 200, height: 42 }}
              type="submit"
              variant="contained"
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
};

export default React.memo(SalesDiscount);

// eslint-disable-next-line react/no-multi-comp
const GridItem = ({ ...rest }) => {
  return (
    <Grid
      item
      key={1}
      lg={6}
      sm={6}
      xs={6}
      {...rest}
    />
  );
};

/* eslint-disable react/display-name */
import React, { useMemo } from 'react';
import {
  Box,
  CardMedia,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { SalesReceipt } from '../../../core/Interface/api.interface';
import { formatDateTime } from '../../../core/utils';

export const Invoice = React.forwardRef<
  HTMLDivElement,
  { salesRes: SalesReceipt; width: number; fontSize: number; label: string }
>(({ salesRes, width, fontSize, label }, ref) => {
  const SalesInvoiceDataMemo = useMemo(() => {
    return salesRes;
  }, [salesRes]);

  const getPriceFormat = (amount: number): string => {
    return amount.toFixed(2);
  };

  return (
    <Box
      ref={ref}
      sx={{
        //  width: '245px',
        maxWidth: width,
        pr: '20px',
        pb: '250px',
        overflow: 'scroll',
        breakAfter: 'always',
        breakBefore: 'always',
        breakInside: 'avoid',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justify: 'center',
        }}
      >
        <CardMedia
          alt="logo"
          component="img"
          image={SalesInvoiceDataMemo.logo}
          sx={{
            height: '80px',
            width: '80px',
            p: 0,
            m: 0,
          }}
        />
        <Typography
          align="center"
          sx={{ fontSize: fontSize }}
        >
          {SalesInvoiceDataMemo.headerText1}
        </Typography>
        <Typography
          align="center"
          sx={{ fontSize: fontSize }}
        >
          {SalesInvoiceDataMemo.headerText2}
        </Typography>
        <Typography
          align="center"
          sx={{ fontSize: fontSize }}
        >
          {SalesInvoiceDataMemo.headerText3}
        </Typography>
        <Typography
          align="center"
          sx={{ fontSize: fontSize }}
        >
          {SalesInvoiceDataMemo.email}
        </Typography>
        <Typography
          align="center"
          sx={{ fontSize: fontSize }}
        >
          {SalesInvoiceDataMemo.mobile}
        </Typography>

        {/* <Box sx={{ height: '20px' }} /> */}
        <Divider
          flexItem
          orientation="horizontal"
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
        }}
      >
        <Box sx={{ height: '7px' }} />
        {(label === '58mm' || label === '80mm') && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '4px',
              textAlign: 'left',
            }}
          >
            <div
              style={{
                flex: '0 0 auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                marginRight: '4px',
              }}
            >
              <Typography sx={{ fontSize: fontSize }}>Order Id</Typography>
              <Typography sx={{ fontSize: fontSize }}>Sales By</Typography>
              <Typography sx={{ fontSize: fontSize }}>Customer</Typography>
              <Typography sx={{ fontSize: fontSize }}>Date</Typography>
            </div>
            <div
              style={{
                flex: '0 0 auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                marginRight: '4px',
              }}
            >
              <Typography sx={{ fontSize: fontSize }}>:</Typography>
              <Typography sx={{ fontSize: fontSize }}>:</Typography>
              <Typography sx={{ fontSize: fontSize }}>:</Typography>
              <Typography sx={{ fontSize: fontSize }}>:</Typography>
            </div>

            <div
              style={{
                flex: '1',
                display: 'flex',
                flexDirection: 'column',
                gap: '0px',
              }}
            >
              <Typography sx={{ fontSize: fontSize }}>
                {SalesInvoiceDataMemo.orderId}
              </Typography>
              <Typography sx={{ fontSize: fontSize }}>
                {SalesInvoiceDataMemo.saleBy}
              </Typography>
              <Typography sx={{ fontSize: fontSize }}>
                {SalesInvoiceDataMemo.customerName}
              </Typography>
              <Typography sx={{ fontSize: fontSize }}>
                {formatDateTime(new Date(SalesInvoiceDataMemo.dateTime))}
              </Typography>
            </div>
          </div>
        )}

        {(label === 'A5' || label === 'A4') && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '4px',
              textAlign: 'left',
            }}
          >
            <div
              style={{
                flex: '0 0 auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                marginRight: '4px',
              }}
            >
              <Typography sx={{ fontSize: fontSize }}>Order Id</Typography>
              <Typography sx={{ fontSize: fontSize }}>Sales By</Typography>
            </div>

            <div
              style={{
                flex: '0 0 auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                marginRight: '4px',
              }}
            >
              <Typography sx={{ fontSize: fontSize }}>:</Typography>
              <Typography sx={{ fontSize: fontSize }}>:</Typography>
            </div>

            <div
              style={{
                flex: '1',
                display: 'flex',
                flexDirection: 'column',
                gap: '0px',
              }}
            >
              <Typography sx={{ fontSize: fontSize }}>
                {SalesInvoiceDataMemo.orderId}
              </Typography>
              <Typography sx={{ fontSize: fontSize }}>
                {SalesInvoiceDataMemo.saleBy}
              </Typography>
            </div>

            <div
              style={{
                flex: '0 0 auto',
                display: 'flex',

                alignItems: 'flex-end',
              }}
            >
              {' '}
              <div
                style={{
                  flex: '0 0 auto',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  marginRight: '4px',
                }}
              >
                <Typography sx={{ fontSize: fontSize }}>Customer</Typography>
                <Typography sx={{ fontSize: fontSize }}>Date</Typography>
              </div>
              <div
                style={{
                  flex: '0 0 auto',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end', // Ensure right-aligned for customer and date
                  marginLeft: '4px',
                  marginRight: '6px',
                }}
              >
                <Typography sx={{ fontSize: fontSize }}>:</Typography>
                <Typography sx={{ fontSize: fontSize }}>:</Typography>
              </div>
              <div
                style={{
                  flex: '1',
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  gap: '0px',
                }}
              >
                <Typography sx={{ fontSize: fontSize }}>
                  {SalesInvoiceDataMemo.customerName}
                </Typography>
                <Typography sx={{ fontSize: fontSize }}>
                  {formatDateTime(new Date(SalesInvoiceDataMemo.dateTime))}
                </Typography>
              </div>
            </div>
          </div>
        )}

        <Divider
          flexItem
          orientation="horizontal"
        />
      </Box>

      {(label === '58mm' || label === '80mm') && (
        <TableContainer sx={{ maxWidth: width }}>
          <Table sx={{ width: '100%' }}>
            <TableHead sx={{ width: '100%' }}>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{ fontSize: fontSize, fontWeight: 800, pl: 0 }}
                >
                  Item
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontSize: fontSize,
                    fontWeight: 800,
                  }}
                >
                  Dis
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ fontSize: fontSize, fontWeight: 800, pr: 0 }}
                >
                  Amount
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {SalesInvoiceDataMemo &&
                SalesInvoiceDataMemo.items &&
                SalesInvoiceDataMemo.items.map((item, index) => (
                  <React.Fragment key={index}>
                    <TableRow sx={{ p: 0, m: 0 }}>
                      <TableCell
                        // colSpan={3}
                        sx={{
                          fontSize: fontSize,
                          textAlign: 'left',
                          width: '100%',
                          p: 0,
                          m: 0,
                        }}
                      >
                        {item.productName}
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ p: 0 }}>
                      <TableCell
                        sx={{
                          fontSize: fontSize,
                          borderBottom: 'none',
                          p: 0,
                          m: 0,
                        }}
                      >
                        {item.qty} × {item.unitPrice}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          fontSize: fontSize,
                          borderBottom: 'none',
                        }}
                      >
                        {item.isDiscountPercentage
                          ? item.discountValue + '%'
                          : item.discountValue}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          fontSize: fontSize,
                          borderBottom: 'none',
                          p: 0,
                          //  pr: '10px', // default zero
                          m: 0,
                        }}
                      >
                        {getPriceFormat(item.amount)}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {(label === 'A4' || label === 'A5') && (
        <TableContainer sx={{ maxWidth: width }}>
          <Table sx={{ width: '100%' }}>
            <TableHead sx={{ width: '100%' }}>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{ fontSize: fontSize, fontWeight: 800, pl: 0 }}
                >
                  Item
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontSize: fontSize, fontWeight: 800 }}
                >
                  Qty
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontSize: fontSize, fontWeight: 800 }}
                >
                  Unit Price
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontSize: fontSize, fontWeight: 800 }}
                >
                  Dis
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ fontSize: fontSize, fontWeight: 800, pr: 0 }}
                >
                  Amount
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ width: '100%' }}>
              {SalesInvoiceDataMemo &&
                SalesInvoiceDataMemo.items &&
                SalesInvoiceDataMemo.items.map((item, index) => (
                  <React.Fragment key={index}>
                    <TableRow sx={{}}>
                      <TableCell
                        // colSpan={3}
                        align="left"
                        sx={{
                          fontSize: fontSize,
                          textAlign: 'left',
                          pl: 0,
                          // width: '100%',
                          // p: 0,
                          // m: 0,
                        }}
                      >
                        {item.productName}
                      </TableCell>

                      <TableCell
                        align="left"
                        sx={{
                          fontSize: fontSize,
                          borderBottom: 'none',
                          // p: 0,
                          // m: 0,
                        }}
                      >
                        {item.qty}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          fontSize: fontSize,
                          borderBottom: 'none',
                          // p: 0,
                          // m: 0,
                        }}
                      >
                        {item.unitPrice}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          fontSize: fontSize,
                          borderBottom: 'none',
                          // p: 0,
                          // //  pr: '20px',
                          // m: 0,
                        }}
                      >
                        {item.isDiscountPercentage
                          ? item.discountValue + '%'
                          : item.discountValue}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          fontSize: fontSize,
                          borderBottom: 'none',
                          pr: 0,
                          // p: 0,
                          // //  pr: '10px', // default zero
                          // m: 0,
                        }}
                      >
                        {getPriceFormat(item.amount)}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Divider
        flexItem
        orientation="horizontal"
      />
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={6}
        >
          <Box>
            <Typography fontSize={fontSize}>Sub Total </Typography>
            <Typography fontSize={fontSize}>
              Discount{' '}
              {SalesInvoiceDataMemo.isDiscountPercentage
                ? '( ' + SalesInvoiceDataMemo.discountValue + '% )'
                : ''}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
        >
          <Box sx={{ mb: '10px', textAlign: 'right' }}>
            <Typography fontSize={fontSize}>
              {getPriceFormat(SalesInvoiceDataMemo.subTotal)}
            </Typography>
            <Typography fontSize={fontSize}>
              {getPriceFormat(SalesInvoiceDataMemo.discount)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider
        flexItem
        orientation="horizontal"
        sx={{ border: '1px dashed #E0E0E0' }}
      />
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={6}
        >
          <Box>
            <Typography fontSize={fontSize}>Total</Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
        >
          <Box sx={{ textAlign: 'right' }}>
            <Typography fontSize={fontSize}>
              {getPriceFormat(SalesInvoiceDataMemo.total)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {SalesInvoiceDataMemo.received !== 0 && (
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={6}
          >
            <Box>
              <Typography fontSize={fontSize}>Received</Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Box sx={{ textAlign: 'right' }}>
              <Typography fontSize={fontSize}>
                {getPriceFormat(SalesInvoiceDataMemo.received)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}
      {SalesInvoiceDataMemo.return !== 0 && (
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={6}
          >
            <Box>
              <Typography fontSize={fontSize}>Return</Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Box sx={{ textAlign: 'right' }}>
              <Typography fontSize={fontSize}>
                {getPriceFormat(SalesInvoiceDataMemo.return ?? 0)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={6}
        >
          <Box>
            <Typography fontSize={fontSize}>Balance</Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
        >
          <Box sx={{ textAlign: 'right' }}>
            <Typography fontSize={fontSize}>
              {getPriceFormat(SalesInvoiceDataMemo.balance)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Divider
        flexItem
        orientation="horizontal"
        sx={{ border: '1px dashed #E0E0E0' }}
      />
      <Box sx={{ height: '10px' }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          align="center"
          sx={{ fontSize: fontSize }}
        >
          {SalesInvoiceDataMemo.footerText1}
        </Typography>
        <Typography
          align="center"
          sx={{ fontSize: fontSize }}
        >
          {SalesInvoiceDataMemo.footerText2}
        </Typography>

        <Typography
          align="center"
          sx={{ fontSize: fontSize }}
        >
          {SalesInvoiceDataMemo.footerText3}
        </Typography>
      </Box>
    </Box>
  );
});

import { useSelector } from 'react-redux';
import {
  finalSummaryStare,
  selectBasket,
  selectBasketItems,
  selectDiscount,
} from './store/BasketSelector';
import { BasketItem } from './store/BasketSlice';
import { useMemo } from 'react';
import { SalesItem, SalesReqPayload } from '../../core/Interface/api.interface';

const useSales = () => {
  const selectItem = useSelector(selectBasketItems);
  const selectDis = useSelector(selectDiscount);
  const paymentStatus = useSelector(finalSummaryStare);
  const selectBasketStore = useSelector(selectBasket);

  const calculateSummary: {
    subTotal: number;
    discount: number;
    total: number;
  } = useMemo(() => {
    const subTotal = selectItem.reduce(getSum, 0);
    const discount = !selectDis.discountAsPercentage
      ? selectDis.discount
      : (subTotal * selectDis.discount) / 100;
    const total = subTotal === 0 ? 0 : subTotal - discount;

    return { subTotal, discount, total };
  }, [selectDis.discount, selectDis.discountAsPercentage, selectItem]);

  const salesDetails: SalesReqPayload = {
    subTotal: calculateSummary.subTotal,
    discount: calculateSummary.discount,
    total: calculateSummary.total,
    totalDiscount: calculateSummary.discount,
    discountValue: selectDis.discount,
    mobile: selectBasketStore.mobile ?? '',
    customerName: selectBasketStore.customerName ?? '',
    isDiscountPercentage: selectDis.discountAsPercentage,
    received: selectBasketStore.receivedAmount ?? 0,
    return: selectBasketStore.returnAmount ?? 0,
    balance:
      (selectBasketStore.receivedAmount
        ? selectBasketStore.receivedAmount
        : 0) +
      (selectBasketStore.returnAmount ?? 0) -
      calculateSummary.total,
    items: selectItem.map((item) => {
      const discount = item.isDiscountPercentage
        ? ((item.unitPrice * (item.defaultDiscountAmount ?? 0)) / 100) *
          item.count
        : (item.defaultDiscountAmount ?? 0) * item.count;
      return {
        qty: item.count,
        amount: item.unitPrice * item.count - discount,
        productId: item.id,
        unitPrice: item.unitPrice,
        discount: discount,
        discountValue: item.defaultDiscountAmount ?? 0,
        isDiscountPercentage: item.isDiscountPercentage ?? false,
      };
    }) as SalesItem[],
  };

  return { calculateSummary, selectItem, salesDetails, paymentStatus };
};

function getSum(total: number, item: BasketItem) {
  if (item.defaultDiscountAmount) {
    if (item.isDiscountPercentage) {
      return (
        total +
        item.unitPrice * item.count -
        ((item.unitPrice * item.defaultDiscountAmount) / 100) * item.count
      );
    }
    return (
      total +
      item.unitPrice * item.count -
      item.defaultDiscountAmount * item.count
    );
  }
  return total + item.unitPrice * item.count;
}

export default useSales;

import { Box, Button, CircularProgress, Grid } from '@mui/material';
import * as React from 'react';
import { addFinalSummaryState, removeBasket } from '../store/BasketSlice';
import SalesDiscount from './SalesDiscount';
import {
  useGetUserQuery,
  useSaveResSalesMutation,
  useSaveSalesMutation,
} from '../../posApi';
import { useNotifier } from '../../../core/Notifier';
import useSales from '../useSales';
import { Invoice } from './Invoice';
import { useReactToPrint } from 'react-to-print';
import { IUserInfo, SalesReceipt } from '../../../core/Interface/api.interface';
import { useAppDispatch } from '../../../store/hooks';
import { useTranslation } from 'react-i18next';
import AlertPopup1 from '../../../components/ModelPopup/AlertPopup1';
import AlertPopup from '../../../components/ModelPopup/AlertPopup';
import { selectPaymentMethod } from '../store/BasketSelector';
import store from '../../../store';
import { calculateFontSize } from '../../../core/utils';

interface SaveResSalesPayload {
  saleId?: string;
  received?: number;
}

interface SalesActionLeftProps {
  onClear: () => void;
  total: number;
  saveResSalesPayload: SaveResSalesPayload;
}

const SalesActionLeft = ({
  onClear,
  total,
  saveResSalesPayload,
}: SalesActionLeftProps) => {
  const { t } = useTranslation(['sales']);
  const dispatch = useAppDispatch();
  const [openDiscount, setOpenDiscount] = React.useState(false);
  const [saveResSales, { isLoading }] = useSaveResSalesMutation();
  const { showErrorMessage, showMessage } = useNotifier();
  const { salesDetails, paymentStatus } = useSales();
  const [openAlert1, setOpenAlert1] = React.useState(false);

  const [saveSales, { isLoading: isSalesLoading }] = useSaveSalesMutation();

  const { data: companyData } = useGetUserQuery();

  const printPaperSizeInPixel = React.useMemo(() => {
    if (companyData?.data) {
      const userData = companyData.data as IUserInfo;
      if (userData.branch.printPaperSizeInPixel) {
        return Number(userData.branch.printPaperSizeInPixel);
      }
    }
    return 10;
  }, [companyData]);

  //const [res, setRes] = React.useState({});
  const [response, setResponse] = React.useState<SalesReceipt>({
    headerText1: '',
    headerText2: '',
    headerText3: '',
    mobile: '',
    email: '',
    footerText1: '',
    footerText2: '',
    footerText3: '',
    logo: '',
    orderId: '',
    customerName: '',
    saleBy: '',
    dateTime: '',
    isDiscountPercentage: false,
    discountValue: 0,
    discount: 0,
    totalDiscount: 0,
    total: 0,
    subTotal: 0,
    received: 0,
    balance: 0,
    return: 0,
    paymentMethod: '',
    items: [
      {
        productId: '',
        qty: 0,
        unitPrice: 0,
        amount: 0,
        isDiscountPercentage: false,
        discountValue: 0,
        discount: 0,
        amountAfterDiscount: 0,
        productName: '',
      },
    ],
  });
  const handlePay = async () => {
    try {
      // const salesRes = await saveSales(salesDetails).unwrap();
      const salesRes = await saveSales({
        ...salesDetails,
        paymentMethod: selectPaymentMethod(store.getState()), // Add paymentMethod here
        isPaid: false,
      }).unwrap();
      if (!salesRes.status) {
        showErrorMessage(salesRes.message);
      } else {
        setResponse(salesRes.data as SalesReceipt);
        showMessage(salesRes.message);
        setTimeout(() => handlePrint(), 200);
        dispatch(removeBasket());
      }
    } catch (error) {
      showErrorMessage((error as Error).message);
    }
  };

  const handleFinish = async () => {
    try {
      // const salesRes = await saveSales(salesDetails).unwrap();
      const salesRes = await saveResSales({
        saleId: saveResSalesPayload?.saleId || '',
        received: salesDetails.balance || 0,
      }).unwrap();
      if (!salesRes.status) {
        showErrorMessage(salesRes.message);
      } else {
        const balance = salesDetails.balance - total;
        setResponse({
          ...(salesRes.data as SalesReceipt),
          balance: balance,
          // Add calculated balance
        });
        // setResponse(salesRes.data as SalesReceipt);
        showMessage(salesRes.message);
        setTimeout(() => handlePrint(), 200);
        onClear();
        dispatch(removeBasket());
      }
    } catch (error) {
      showErrorMessage((error as Error).message);
    }
  };

  const pay = async () => {
    // if (!paymentStatus) {
    //   dispatch(addFinalSummaryState({ finalSummaryStare: true }));
    //   return;
    // }
    console.log('paymentStatus', paymentStatus);
    if (paymentStatus && salesDetails.balance - total < 0) {
      setOpenAlert(true);
      return;
    }

    try {
      let shouldOpenAlert1 = false;
      console.log('salesDetails.balance', salesDetails.balance);
      console.log('salesDetails.mobile', salesDetails.mobile);

      if (shouldOpenAlert1) {
        setOpenAlert1(true);
      } else {
        if (paymentStatus) {
          await handleFinish();
        } else if (!paymentStatus) {
          console.log('else if', paymentStatus);
          await handlePay();
        }
      }
    } catch (error) {
      showErrorMessage('Please check your data');
    }
  };

  // const componentRef = React.useRef();
  const componentRef = React.useRef<HTMLDivElement | null>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleCloseAlert1 = () => {
    setOpenAlert1(false);
  };

  return (
    <Box
      sx={{
        pb: 4,
        position: 'relative',
        left: 'auto',
        right: 'auto',
        zIndex: 'auto',
      }}
    >
      <div style={{ display: 'none' }}>
        <Invoice
          ref={componentRef}
          salesRes={response}
          fontSize={calculateFontSize(printPaperSizeInPixel).fontSize}
          label={calculateFontSize(printPaperSizeInPixel).label}
          width={printPaperSizeInPixel}
        />
      </div>
      <Grid
        alignItems="center"
        container
        justifyContent="space-between"
        display="flex"
        // sx={{ height: '100%', minHeight: '100%' }}
      >
        <Grid
          item
          lg={4}
          md={4}
          sm={6}
          sx={{ pb: 2 }}
          xs={1}
        >
          <Button
            onClick={() => {
              dispatch(removeBasket());
              onClear();
            }}
            sx={{ width: 130, mr: 3 }}
            variant="outlined"
          >
            {t('sales:clear')}
          </Button>
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          sm={6}
          sx={{ pb: 2 }}
          xs={12}
        >
          <Button
            onClick={() => setOpenDiscount(true)}
            sx={{ width: 130, mr: 3 }}
            variant="outlined"
          >
            {t('sales:dic')}
          </Button>
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          sm={6}
          sx={{ pb: 2 }}
          xs={12}
        >
          <Button
            color="success"
            disabled={
              isLoading || (!paymentStatus && salesDetails.items.length === 0)
            }
            onClick={pay}
            sx={{
              width: 130,
              height: 40,
            }}
            variant="contained"
          >
            {isLoading && (
              <Box sx={{ pr: 2, pt: 0.5 }}>
                <CircularProgress
                  size={16}
                  sx={{ color: 'white' }}
                />
              </Box>
            )}

            {!paymentStatus ? t('sales:pay') : t('sales:finish')}
          </Button>
        </Grid>
      </Grid>
      {openDiscount && (
        <SalesDiscount
          handleCloseDialog={() => setOpenDiscount(false)}
          openModel={openDiscount}
          title={`Add Discount`}
        />
      )}
      <AlertPopup
        open={openAlert}
        handleCloseAlert={handleCloseAlert}
      />
      {openAlert1 && (
        <AlertPopup1
          open={openAlert1}
          handleCloseDialog={handleCloseAlert1}
          openModel={openAlert1}
          handlePay={handlePay}
        />
      )}
    </Box>
  );
};

export default React.memo(SalesActionLeft);

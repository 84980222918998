import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface IAlertPopup {
  open: boolean;
  handleCloseAlert: () => void;
}

const AlertPopup = ({ open, handleCloseAlert }: IAlertPopup) => {
  const { t } = useTranslation(['common']);

  const handleClose = () => {
    handleCloseAlert();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle
        sx={{
          background: '#CFD8DC',
          paddingRight: '24px',
          borderRadius: 1,
          p: 4,
          py: 2,
          gap: 2,
        }}
      >
        <strong> Alert</strong>
      </DialogTitle>
      <DialogContent
        sx={{
          paddingRight: '24px',
          p: 4,
          py: 2,
          gap: 2,
        }}
      >
        <DialogContentText
          sx={{
            pt: 2,
          }}
        >
          Received amount is less than Total due.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ paddingRight: '24px', p: 4, py: 3, gap: 2 }}>
        <Button
          onClick={handleClose}
          color="primary"
          sx={{
            width: 130,
            height: 42,
            borderRadius: '5px',
            fontWeight: 'bold',
          }}
          variant="outlined"
        >
          {t('common:cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AlertPopup;

//

import { Box, BoxProps, Skeleton } from '@mui/material';
import React from 'react';

/**
 * Skeleton component to show the skeleton of the HomeTop
 *
 */
const TopInfoSkeleton = (props: BoxProps) => {
  return (
    <Box
      height={120}
      {...props}
    >
      <Skeleton
        height={120}
        variant="rectangular"
        width={'100%'}
      />
    </Box>
  );
};

export default React.memo(TopInfoSkeleton);

import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Avatar, Grid, Tooltip, useMediaQuery } from '@mui/material';
import { Privileges } from '../../core/types/commonType';
import DashboardIcon from '@mui/icons-material/Dashboard';
import useUser from '../../core/hooks/useUser';
import { appColors } from '../../theme/appColors';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { IUser, IUserInfo } from '../../core/Interface/api.interface';
import useApp from '../../utils/useApp';
import { useGetUserQuery } from '../../page/posApi';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import StoreIcon from '@mui/icons-material/Store';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import AssignmentReturnOutlinedIcon from '@mui/icons-material/AssignmentReturnOutlined';
import AppsIcon from '@mui/icons-material/Apps';
import ClassIcon from '@mui/icons-material/Class';
import ListIcon from '@mui/icons-material/List';
import MedicationLiquidIcon from '@mui/icons-material/MedicationLiquid';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import MedicationIcon from '@mui/icons-material/Medication';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import DiscountIcon from '@mui/icons-material/Discount';
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import Inventory from '@mui/icons-material/Inventory';
import SellIcon from '@mui/icons-material/Sell';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import PaymentIcon from '@mui/icons-material/Payment';
import GroupIcon from '@mui/icons-material/Groups';
import CategoryIcon from '@mui/icons-material/Category';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ProfilePopup from '../../components/ModelPopup/ProfilePopup';
import Typewriter from 'typewriter-effect';

const drawerWidth = 240;

type SubmenuItem = {
  title: string;
  path: string;
  privilege: string;
  newPage?: boolean;
  icon: React.ReactElement;
  open?: boolean;
};

type MenuItem = {
  title: string;
  icon: React.ReactElement;
  path?: string;
  newPage?: boolean;
  privilege?: string;
  submenuOpen?: boolean;
  submenus?: SubmenuItem[];
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 10px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 10px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  fullView?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open, fullView }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: fullView ? '100%' : `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));
const menuList: MenuItem[] = [
  {
    title: 'Dashboard',
    icon: <DashboardIcon />,
    path: '/dashboard',
    privilege: Privileges.Dashboard,
  },
  {
    title: 'Catalog',
    icon: <ClassIcon />,
    submenuOpen: true,
    submenus: [
      {
        title: 'Category',
        icon: <CategoryIcon />,
        path: '/category',
        privilege: Privileges.CategoryView,
      },
      {
        title: 'Product',
        icon: <ProductionQuantityLimitsIcon />,
        path: '/product',
        privilege: Privileges.ProductView,
      },
      {
        title: 'Inventory',
        icon: <InventoryOutlinedIcon />,
        path: '/inventory-details',
        privilege: Privileges.Inventory,
      },
    ],
  },
  {
    title: 'Sales',
    icon: <PointOfSaleIcon />,
    submenuOpen: true,
    submenus: [
      {
        title: 'Sales',
        icon: <PointOfSaleIcon />,
        path: '/sales',
        privilege: Privileges.Sales,
      },
      {
        title: 'Sale(R)',
        icon: <DashboardIcon />,
        path: '/restaurantSales',
        privilege: Privileges.RestaurantSales,
      },
      {
        title: 'Sales List',
        icon: <FileCopyIcon />,
        path: '/sales-list',
        privilege: Privileges.SalesList,
      },
    ],
  },
  {
    title: 'Purchase',
    icon: <Inventory />,
    submenuOpen: true,
    submenus: [
      {
        title: 'Purchase',
        icon: <Inventory />,
        path: '/purchase',
        privilege: Privileges.Purchase,
      },
      {
        title: 'Purchase List',
        icon: <SellIcon />,
        path: '/purchase-list',
        privilege: Privileges.PurchaseList,
      },
    ],
  },
  {
    title: 'Return',
    icon: <AssignmentReturnOutlinedIcon />,
    submenuOpen: true,
    submenus: [
      {
        title: 'Return',
        icon: <AssignmentReturnOutlinedIcon />,
        path: '/return-sales',
        privilege: Privileges.SalesReturn,
      },
      {
        title: 'Return List',
        icon: <KeyboardReturnIcon />,
        path: '/return-list',
        privilege: Privileges.SalesReturn,
      },
    ],
  },
  {
    title: 'Vehicle',
    icon: <AssignmentReturnOutlinedIcon />,
    submenuOpen: true,
    submenus: [
      {
        title: 'Vehicle Request',
        icon: <AssignmentReturnOutlinedIcon />,
        path: '/employeeRequest-form',
        privilege: Privileges.Construction,
      },
      {
        title: 'Assign Vehicles',
        icon: <AssignmentReturnOutlinedIcon />,
        path: '/adminAcceptRequest',
        privilege: Privileges.Construction,
      },
    ],
  },

  {
    title: 'Others',
    icon: <AppsIcon />,
    submenuOpen: true,
    submenus: [
      {
        title: 'Customer',
        icon: <SupportAgentIcon />,
        path: '/customer-management',
        privilege: Privileges.CustomerManageView,
      },
      {
        title: 'Supplier',
        icon: <GroupIcon />,
        path: '/Supplier-management',
        privilege: Privileges.SupplierManageView,
      },
      {
        title: 'Company',
        icon: <AddBusinessIcon />,
        path: '/company-management',
        privilege: Privileges.CompanyManageView,
      },
      {
        title: 'Branch',
        icon: <StoreIcon />,
        path: '/branch-management',
        privilege: Privileges.BranchManageView,
      },
      {
        title: 'User',
        icon: <PeopleAltIcon />,
        path: '/user-management',
        privilege: Privileges.UserManageView,
      },
      {
        title: 'Message',
        icon: <ForwardToInboxIcon />,
        path: '/send-sms',
        privilege: Privileges.Construction,
      },
    ],
  },
  {
    title: 'Construction',
    icon: <EngineeringIcon />,
    submenuOpen: true,
    submenus: [
      {
        title: 'Machineries',
        icon: <AgricultureIcon />,
        path: '/machineries-management',
        privilege: Privileges.Construction,
      },
      {
        title: 'PendingRequest',
        icon: <RvHookupIcon />,
        path: '/pending-request',
        privilege: Privileges.Construction,
      },
      {
        title: 'Promotion',
        icon: <DiscountIcon />,
        path: '/promotion',
        privilege: Privileges.Construction,
      },
      {
        title: 'Addresses',
        icon: <AddHomeWorkIcon />,
        path: '/addresses',
        privilege: Privileges.Construction,
      },
    ],
  },
  {
    title: 'Bill Payments',
    icon: <PaymentsOutlinedIcon />,
    submenuOpen: true,
    submenus: [
      {
        title: 'Payment',
        icon: <PaymentIcon />,
        path: '/billPayments',
        privilege: Privileges.BillPayments,
      },
      {
        title: 'History',
        icon: <ListIcon />,
        path: '/bill-history',
        privilege: Privileges.BillPayments,
      },
    ],
  },
  {
    title: 'Pharmacy',
    icon: <MedicationLiquidIcon />,
    submenuOpen: true,
    submenus: [
      {
        title: 'Treatments',
        icon: <AccessibilityNewIcon />,
        path: '/pharmacy/treatments',
        privilege: Privileges.PatientView,
      },
      {
        title: 'Patient',
        icon: <PeopleAltIcon />,
        path: '/pharmacy/patients',
        privilege: Privileges.PatientView,
      },
      {
        title: 'Dental',
        icon: <MedicationIcon />,
        path: '/pharmacy/dental',
        privilege: Privileges.dentalTreatmentView,
      },
    ],
  },
  {
    title: 'Reports',
    icon: <SummarizeIcon />,
    submenuOpen: true,
    submenus: [
      {
        title: 'Daily Summary',
        icon: <AssessmentIcon />,
        path: '/dailyReports',
        privilege: Privileges.ReportDailySummary,
      },
      {
        title: 'Monthly Summary',
        icon: <SummarizeIcon />,
        path: '/monthlyReports',
        privilege: Privileges.ReportDailySummary,
      },
    ],
  },
];

export default function MiniDrawer({
  children,
}: {
  children: React.ReactNode;
}) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const { userHasRight } = useUser();
  const navigate = useNavigate();
  const { currentMenuName } = useApp();
  const { data } = useGetUserQuery();
  const [expandMenu, setExpandMenu] = React.useState<string>('');
  const [profile, setProfile] = React.useState<HTMLElement | null>(null);

  const userName = useMemo(() => {
    if (data?.data) {
      const userData = data.data as IUser;
      return userData.username;
    }
    return false;
  }, [data]);

  const companyName = useMemo(() => {
    if (data?.data) {
      const userData = data.data as IUserInfo;
      if (userData.company) {
        return userData.company.name;
      }
    }
    return false;
  }, [data]);

  const branchName = useMemo(() => {
    if (data?.data) {
      const userData = data.data as IUserInfo;
      return userData.branch.name;
    }
    return '';
  }, [data]);

  const noticeText = useMemo(() => {
    if (data?.data) {
      const noticeTextData = data.data as IUserInfo;
      return noticeTextData.noticeText;
    }
    return '';
  }, [data]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setProfile(event.currentTarget);
  };

  const handleClose = () => {
    setProfile(null);
  };

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [openDrawer, setDrawer] = React.useState(isSmall);
  const handleMainMenuItemClick = (ml: MenuItem) => {
    if (isSmall) {
      // setDrawer(!openDrawer);
    }

    if (ml.submenus && ml.submenus.length > 0) {
      // const updatedSubmenus = [...openSubmenus];
      // const submenuIndex = updatedSubmenus.indexOf(ml.title);
      // if (submenuIndex === -1) {
      //   updatedSubmenus.push(ml.title);
      // } else {
      //   updatedSubmenus.splice(submenuIndex, 1);
      // }
      // setOpenSubmenus(updatedSubmenus);
      ml.title === expandMenu ? setExpandMenu('') : setExpandMenu(ml.title);
    } else if (ml.path) {
      navigate(ml.path);
    }
  };
  React.useEffect(() => {
    if (
      currentMenuName === '/sales' ||
      currentMenuName === '/purchase' ||
      currentMenuName === '/restaurantSales'
    ) {
      setOpen(true);
    }
  }, [currentMenuName]);
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        open={open}
        position="fixed"
        fullView={
          currentMenuName === '/sales' ||
          currentMenuName === '/purchase' ||
          currentMenuName === '/restaurantSales'
        }
      >
        <Toolbar>
          {currentMenuName === '/sales' ||
          currentMenuName === '/restaurantSales' ||
          currentMenuName === '/purchase' ? null : (
            <IconButton
              aria-label="open drawer"
              color="inherit"
              edge="start"
              onClick={handleDrawerOpen}
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Grid
            container
            justifyContent="space-between"
          >
            <Grid
              item
              lg={8.5}
              md={8}
            >
              <Grid
                container
                justifyContent="space-between"
              >
                {!isSmall && (
                  <Grid
                    item
                    lg={4}
                    md={5}
                    sm={6}
                    sx={{
                      fontSize: { xs: '0.6rem', sm: '1.4rem' },
                      pt: 1.5,
                      fontWeight: 800,
                      color: appColors.white,
                    }}
                  >
                    {companyName}
                  </Grid>
                )}

                <Grid
                  item
                  lg={7}
                  md={7}
                  sm={6}
                >
                  {!isSmall && noticeText && (
                    <Box
                      sx={{
                        pt: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      <Typewriter
                        options={{
                          strings: noticeText,
                          autoStart: true,
                          delay: 80,
                          loop: true,
                          deleteSpeed: 1,
                        }}
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              lg={3.5}
              md={4}
            >
              <Grid
                container
                data-testid="user-content"
                justifyContent="flex-end"
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: isSmall ? '0.7rem' : '0.9rem',
                      fontWeight: isSmall ? 400 : 600,
                      color: appColors.white,
                      pt: 1,
                    }}
                  >
                    {userName}
                  </Typography>
                  {branchName && (
                    <Typography
                      sx={{
                        fontSize: isSmall ? '0.5rem' : '0.7rem',
                        fontWeight: 'normal',
                        color: appColors.white,
                      }}
                    >
                      Branch : {branchName}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Tooltip
                    sx={{ fontSize: '14px', fontWeight: 'bold' }}
                    title="Open settings"
                  >
                    <IconButton
                      onClick={handleClick}
                      sx={{
                        pt: 1,
                        borderRadius: '16px',
                        cursor: 'pointer',
                        height: '50px',
                        width: '50px',
                        fontWeight: 'bold',
                        '&:hover': {
                          backgroundColor: appColors.greyishBlue[40],
                        },
                      }}
                    >
                      <Avatar
                        sx={{
                          ...(isSmall && {
                            height: '20px',
                            width: '20px',
                          }),
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>

              <ProfilePopup
                Branchname={branchName}
                Username={userName}
                onClose={handleClose}
                open={Boolean(profile)}
                profile={profile}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {currentMenuName === '/sales' ||
      currentMenuName === '/purchase' ||
      currentMenuName === '/restaurantSales' ? null : (
        <Drawer
          open={open}
          variant="permanent"
        >
          <DrawerHeader>
            <Box alignItems="left">
              <img
                height={50}
                src={'/images/logo.png'}
                width={80}
              />
            </Box>
            <Typography
              sx={{ color: appColors.black, pl: 2, pr: 10, fontWeight: 800 }}
            ></Typography>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List sx={{ pl: 1.5 }}>
            {menuList.map((ml, index) => {
              const shouldRenderMainMenuItem =
                (!ml.privilege || userHasRight(ml.privilege)) &&
                (!ml.submenus ||
                  ml.submenus.some(
                    (submenu) =>
                      !submenu.privilege || userHasRight(submenu.privilege)
                  ));

              if (shouldRenderMainMenuItem) {
                return (
                  <div key={ml.title}>
                    <ListItem
                      disablePadding
                      key={ml.title}
                      sx={{
                        ...(isSmall && {
                          fontSize: '0.75rem',
                          width: 5,
                          height: 15,
                          fontWeight: 400,
                          pb: 2,
                          pt: 2,
                        }),
                      }}
                    >
                      {/* Main menu item */}
                      <Tooltip
                        arrow
                        placement="right-start"
                        title={!open ? ml.title : ''}
                      >
                        <ListItemButton
                          onClick={() => handleMainMenuItemClick(ml)}
                          sx={{
                            p: isSmall ? 0.5 : 1,
                            gap: isSmall ? 0.5 : 1,
                            justifyContent: 'space-between',
                            borderRight:
                              currentMenuName === ml.path
                                ? `4px solid ${appColors.royalBlue[100]}`
                                : 'none',
                            pt: open ? 1 : 2,
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: isSmall ? 4 : 8,
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: isSmall ? 10 : 20,
                              }}
                            >
                              {ml.icon as React.ReactNode}
                            </ListItemIcon>
                            {open && (
                              <ListItemText
                                primary={
                                  ml.privilege && userHasRight(ml.privilege)
                                    ? ml.title
                                    : ml.title
                                }
                                primaryTypographyProps={{
                                  ...(isSmall && {
                                    fontSize: '0.75rem',

                                    fontWeight: 700,
                                  }),
                                }}
                                sx={{
                                  opacity: open ? 1 : 0,
                                  fontWeight: 700,
                                }}
                              />
                            )}
                          </div>
                          {ml.submenus &&
                            (expandMenu === ml.title ? (
                              <ExpandLessIcon
                                sx={{
                                  ...(isSmall && {
                                    height: 15,
                                    width: 15,
                                  }),
                                }}
                              />
                            ) : (
                              <ExpandMoreIcon
                                sx={{
                                  ...(isSmall && {
                                    height: 15,
                                    width: 15,
                                  }),
                                }}
                              />
                            ))}
                        </ListItemButton>
                      </Tooltip>
                    </ListItem>

                    {/* Submenus */}
                    {ml.submenus && (
                      <Collapse
                        in={expandMenu === ml.title}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List>
                          {ml.submenus.map((submenu) => {
                            const hasSubMenuRight =
                              !submenu.privilege ||
                              userHasRight(submenu.privilege);

                            return (
                              hasSubMenuRight && (
                                <ListItem
                                  disablePadding
                                  key={submenu.title}
                                  sx={{
                                    ...(isSmall && {
                                      fontSize: '0.75rem',
                                      width: 5,
                                      height: 15,
                                      fontWeight: 700,
                                      pb: 2,
                                      pt: 2,
                                    }),
                                  }}
                                >
                                  <Tooltip
                                    arrow
                                    placement="right-start"
                                    title={!open ? submenu.title : ''}
                                  >
                                    <ListItemButton
                                      onClick={() => {
                                        if (isSmall) {
                                          setDrawer(!openDrawer);
                                        }
                                        if (submenu.newPage) {
                                          window.open(submenu.path, '_blank');
                                        } else {
                                          if (currentMenuName !== submenu.path)
                                            navigate(submenu.path);
                                        }
                                      }}
                                      sx={{
                                        background:
                                          currentMenuName === submenu.path
                                            ? appColors.darkGray[10]
                                            : '',
                                        p: isSmall ? 0.5 : 1,
                                        gap: isSmall ? 0.5 : 1,
                                        borderRight:
                                          currentMenuName === submenu.path
                                            ? `4px solid ${appColors.royalBlue[100]}`
                                            : 'none',
                                      }}
                                    >
                                      <ListItemIcon>
                                        {submenu.icon as React.ReactNode}
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={
                                          hasSubMenuRight ? submenu.title : ''
                                        }
                                        primaryTypographyProps={{
                                          ...(isSmall && {
                                            fontSize: '0.75rem',

                                            fontWeight: 700,
                                          }),
                                        }}
                                        sx={{
                                          opacity: open ? 1 : 0,
                                        }}
                                      />
                                    </ListItemButton>
                                  </Tooltip>
                                </ListItem>
                              )
                            );
                          })}
                        </List>
                      </Collapse>
                    )}
                  </div>
                );
              }
              return null;
            })}
          </List>
        </Drawer>
      )}
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3 }}
      >
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}

import { Box, Grid, Paper, Stack, Typography, styled } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { appColors } from '../../../theme/appColors';
import { getPriceFormat } from '../../../core/utils';
import { rem } from '../../../theme/typography';
import useSales from '../useSales';
import {
  addFinalSummaryState,
  addReceivedMoney,
  addReturnAmount,
  addPaymentMethod,
} from '../store/BasketSlice';
import { useAppDispatch } from '../../../store/hooks';
import { useTranslation } from 'react-i18next';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PaymentsIcon from '@mui/icons-material/Payments';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { selectPaymentMethod } from '../store/BasketSelector';
import { useGetUserQuery } from '../../posApi';
import { IUserInfo } from '../../../core/Interface/api.interface';

interface FinalSummaryProps {
  subtotal: number;
  total: number;
  discount: number;
}

const FinalSummary = (props: FinalSummaryProps) => {
  const [receivedCache, setReceivedCache] = useState(0);
  const { calculateSummary } = useSales();
  const [enterAmount, setEnterAmount] = useState('');
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['sales']);
  const [returnCache, setReturnCache] = useState(0);
  const [isEditingReturn, setIsEditingReturn] = useState(false);
  const [isEditingRecived, setIsEditingRecived] = useState(false);
  const paymentMethod = useSelector(selectPaymentMethod);

  useEffect(() => {
    dispatch(addReceivedMoney(receivedCache));
    dispatch(addReturnAmount(returnCache));
    console.log('receivedCache', receivedCache);
  }, [receivedCache, returnCache]);

  const { data: companyData } = useGetUserQuery();

  const currencyCode = useMemo(() => {
    if (companyData?.data) {
      const userData = companyData.data as IUserInfo;
      if (userData.company.currencyCode) {
        return userData.company.currencyCode;
      }
    }
    return '';
  }, [companyData]);

  useEffect(() => {
    const handleKeyDown = (event: {
      preventDefault(): unknown;
      key: string;
    }) => {
      if (!isNaN(parseInt(event.key))) {
        setIsEditingRecived(true);
      } else if (event.key === 'Tab' && isEditingRecived) {
        setIsEditingRecived(false);
        setIsEditingReturn(true);
        event.preventDefault();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isEditingRecived]);

  const formik = useFormik({
    initialValues: {
      paymentMethod: 'Cash',
    },
    onSubmit: (values) => {
      dispatch(addPaymentMethod(values.paymentMethod));
    },
  });

  const handlePaymentMethodSelect = (method: string) => {
    dispatch(addPaymentMethod(method));
    if (method === 'Card' || method === 'Cheque') {
      setReceivedCache(calculateSummary.total);
    } else {
      setReceivedCache(0);
    }
  };
  useEffect(() => {
    handlePaymentMethodSelect('Cash');
  }, []);

  return (
    <Box sx={{ pb: 4 }}>
      <Stack
        alignItems="center"
        direction="row"
        gap={3}
        onClick={() =>
          dispatch(addFinalSummaryState({ finalSummaryStare: false }))
        }
        sx={{ cursor: 'pointer', px: 3, pt: 1 }}
      >
        <ArrowBackIosNewIcon />
        <Typography
          sx={{ fontWeight: 600 }}
          variant="h6"
        >
          Back
        </Typography>
      </Stack>
      <Grid
        container
        sx={{ pt: 2, pl: 4 }}
      >
        <Grid
          item
          lg={6}
          md={6}
          xl={6}
          xs={6}
        >
          <Box>
            <Grid
              container
              spacing={2}
            >
              <CacheBalanceGrid
                amount={props.total}
                name="Total Due"
                currencyCode={currencyCode}
              />
              <CacheBalanceGrid
                amount={receivedCache}
                name="Received"
                editable={isEditingRecived}
                onToggleEdit={() => setIsEditingRecived(!isEditingRecived)}
                onAmountChange={(newAmount: number) => {
                  setReceivedCache(newAmount);
                  console.log('Received', newAmount);
                }}
                currencyCode={currencyCode}
              />
              <CacheBalanceGrid
                amount={returnCache}
                currencyCode={currencyCode}
                name="Return"
                editable={isEditingReturn}
                onToggleEdit={() => setIsEditingReturn(!isEditingReturn)}
                onAmountChange={(newAmount: number) =>
                  setReturnCache(newAmount)
                }
              />
              <CacheBalanceGrid
                currencyCode={currencyCode}
                amount={receivedCache - props.total + returnCache}
                name="Balance"
                balanceColor={
                  receivedCache - props.total + returnCache !== 0
                    ? receivedCache - props.total + returnCache < 0
                      ? appColors.carmineRed[120]
                      : appColors.darkGreen[80]
                    : 'black'
                }
              />
            </Grid>
            <Box sx={{ bottom: 40, position: 'fixed' }}>
              <Grid
                container
                spacing={1}
              >
                {[5, 10, 20, 50, 100, 500, 1000, 5000].map((item) => (
                  <Grid
                    item
                    key={item}
                    lg={3}
                    md={3}
                    xl={3}
                    xs={4}
                  >
                    <CacheItem onClick={() => setReceivedCache(item)}>
                      {item}
                    </CacheItem>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          lg={6}
          pr={2}
        >
          <Grid
            container
            justifyContent="space-between"
          >
            <Grid
              container
              justifyContent="space-between"
            >
              <Grid
                item
                lg={3.7}
                md={3.8}
                sm={3.9}
                xs={3.9}
                onClick={() => handlePaymentMethodSelect('Cash')}
                {...formik.getFieldProps('paymentMethod')}
                sx={{
                  py: 0.5,
                  border: '2px solid #E6E8F3',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  borderRadius: 2,
                  backgroundColor:
                    paymentMethod === 'Cash'
                      ? appColors.reflexBlue[10]
                      : 'transparent',
                  '&:hover': {
                    backgroundColor: appColors.reflexBlue[10],
                    cursor: 'pointer',
                  },
                }}
              >
                <PaymentsIcon style={{ width: 30, height: 30 }} />
                <Typography sx={{ fontWeight: 500, fontSize: '1rem' }}>
                  Cash
                </Typography>
              </Grid>
              <Grid
                item
                lg={3.7}
                md={3.8}
                sm={3.9}
                xs={3.9}
                onClick={() => handlePaymentMethodSelect('Card')}
                {...formik.getFieldProps('paymentMethod')}
                sx={{
                  py: 0.5,
                  border: '2px solid #E6E8F3',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  borderRadius: 2,
                  backgroundColor:
                    paymentMethod === 'Card'
                      ? appColors.reflexBlue[10]
                      : 'transparent',
                  '&:hover': {
                    backgroundColor: appColors.reflexBlue[10],
                    cursor: 'pointer',
                  },
                }}
              >
                <CreditCardIcon style={{ width: 30, height: 30 }} />
                <Typography sx={{ fontWeight: 500, fontSize: '1rem' }}>
                  Card
                </Typography>
              </Grid>
              <Grid
                item
                lg={3.7}
                md={3.8}
                sm={3.9}
                xs={3.9}
                onClick={() => handlePaymentMethodSelect('Cheque')}
                {...formik.getFieldProps('paymentMethod')}
                sx={{
                  py: 0.5,
                  border: '2px solid #E6E8F3',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  borderRadius: 2,
                  backgroundColor:
                    paymentMethod === 'Cheque'
                      ? appColors.reflexBlue[10]
                      : 'transparent',
                  '&:hover': {
                    backgroundColor: appColors.reflexBlue[10],
                    cursor: 'pointer',
                  },
                }}
              >
                <ReceiptIcon style={{ width: 30, height: 30 }} />
                <Typography sx={{ fontWeight: 500, fontSize: '1rem' }}>
                  Cheque
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          lg={6}
        />
        <Grid
          item
          lg={6}
          md={6}
          xl={6}
          xs={6}
        >
          <Box sx={{ bottom: 40, pr: 5, position: 'fixed' }}>
            <Typography
              sx={{ textAlign: 'end', fontSize: 24, fontWeight: 800 }}
            >
              {Number(enterAmount)}
            </Typography>

            <CachePanel
              addAmount={(amount) => {
                setEnterAmount(enterAmount + amount);
              }}
              cashPanelAction={(type) => {
                if (type === 'c') {
                  setEnterAmount('');
                  if (isEditingReturn) {
                    setReturnCache(0);
                    setIsEditingReturn(false);
                  }
                } else if (type === 'a') {
                  setReceivedCache(Number(enterAmount));
                } else if (type === 'd') {
                  setReturnCache(parseFloat(enterAmount) || 0);
                }
              }}
              isEditingReturn={isEditingReturn}
              onToggleEdit={() => setIsEditingReturn(!isEditingReturn)}
              setReturnAmount={(amount) => setReturnCache(amount)}
              enterAmount={enterAmount}
              setEnterAmount={setEnterAmount}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FinalSummary;

const CachePanel = ({
  addAmount,
  cashPanelAction,
  isEditingReturn,
  onToggleEdit,
  setReturnAmount,
  enterAmount,
  setEnterAmount,
}: {
  addAmount: (amount: string) => void;
  cashPanelAction: (type: string) => void;
  isEditingReturn: boolean;
  onToggleEdit: () => void;
  setReturnAmount: (amount: number) => void;
  enterAmount: string;
  setEnterAmount: (amount: string) => void;
}) => {
  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleAccept = () => {
    if (isEditingReturn) {
      setReturnAmount(parseFloat(enterAmount) || 0);
      onToggleEdit();
      setEnterAmount('');
    } else {
      cashPanelAction('a');
      setEnterAmount('');
    }
  };

  return (
    <Grid
      container
      spacing={1}
    >
      {['.', '00', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
        .reverse()
        .map((item) => (
          <Grid
            item
            key={item}
            xs={4}
          >
            <Item
              onMouseDown={handleMouseDown}
              onClick={() => addAmount(item)}
            >
              {item}
            </Item>
          </Grid>
        ))}
      <Grid
        item
        key={11}
        xs={4}
      >
        <Item onMouseDown={handleMouseDown}>Extract</Item>
      </Grid>
      <Grid
        item
        key={12}
        xs={4}
      >
        <Item
          onMouseDown={handleMouseDown}
          onClick={() => {
            cashPanelAction('c');
            if (isEditingReturn) {
              setReturnAmount(0);
              onToggleEdit();
            }
            setEnterAmount('');
          }}
        >
          Clear
        </Item>
      </Grid>
      <Grid
        item
        key={13}
        xs={4}
      >
        <Item
          onMouseDown={handleMouseDown}
          onClick={handleAccept}
        >
          {isEditingReturn ? 'Return' : 'Accept'}
        </Item>
      </Grid>
    </Grid>
  );
};

const CacheBalanceGrid = ({
  name,
  amount,
  currencyCode,
  editable,
  onToggleEdit,
  onAmountChange,
  balanceColor,
}: {
  name: string;
  amount: number;
  currencyCode: string;
  editable?: boolean;
  onToggleEdit?: () => void;
  onAmountChange?: (newAmount: number) => void;
  balanceColor?: string;
}) => {
  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newAmount = parseFloat(event.target.value) || 0;
    if (onAmountChange) {
      onAmountChange(newAmount);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (onToggleEdit) {
        onToggleEdit();
      }
    }
  };

  const StyledInput = styled('input')({
    border: 'none',
    borderBottomStyle: 'dotted',
    borderBottom: '1px thick green',
    width: 200,
    fontSize: 22,
    outline: 'none',
    '&:focus': {
      borderBottom: '1px thick green',
    },
  });

  const balanceStyle: React.CSSProperties = {
    borderBottomStyle: 'dotted',
    borderBottom: '1px thick green',
    width: 200,
    fontSize: 22,
    fontWeight: balanceColor && balanceColor !== 'black' ? 800 : 'normal',
    color: balanceColor
      ? balanceColor === appColors.carmineRed[120]
        ? appColors.carmineRed[120]
        : appColors.darkGreen[80]
      : 'black',
  };

  return (
    <>
      <Grid
        item
        key={name}
        xs={4}
      >
        <Typography sx={{ pb: 1, fontSize: 22 }}>{name}</Typography>
      </Grid>
      <Grid
        item
        key={name + '_' + amount}
        xs={8}
      >
        {editable ? (
          <StyledInput
            type="number"
            value={amount}
            onChange={handleAmountChange}
            onBlur={onToggleEdit}
            onKeyDown={handleKeyDown}
            autoFocus
          />
        ) : (
          <Typography
            component="div"
            sx={balanceStyle}
            onClick={onToggleEdit}
          >
            {getPriceFormat(amount, currencyCode)}
          </Typography>
        )}
      </Grid>
    </>
  );
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: appColors.greenSmoke[40],
  ...theme.typography.body2,
  padding: theme.spacing(1),

  pr: 0,
  fontSize: 20,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  cursor: 'pointer',
}));

const CacheItem = styled(Paper)(({ theme }) => ({
  backgroundColor: appColors.greenSmoke[40],
  ...theme.typography.body2,
  padding: theme.spacing(1),
  fontSize: rem(18),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  cursor: 'pointer',
}));

import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface IMobile {
  open: boolean;
  openModel?: boolean;
  handleCloseDialog: (close: boolean) => void;
  handlePay: () => void;
}

const AlertPopup1: React.FC<IMobile> = ({
  openModel = false,
  handleCloseDialog,
  handlePay,
}) => {
  const { t } = useTranslation(['common', 'sales']);
  const [open] = React.useState(openModel);

  const handleClose = () => {
    handleCloseDialog(false);
  };

  const handlePayClick = () => {
    handlePay();
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle
        sx={{
          background: '#CFD8DC',
          paddingRight: '24px',
          borderRadius: 1,
          p: 4,
          py: 2,
          gap: 2,
        }}
      >
        <strong> Alert</strong>
      </DialogTitle>
      <DialogContent
        sx={{
          paddingRight: '24px',
          p: 4,
          py: 2,
          gap: 2,
        }}
      >
        <DialogContentText
          sx={{
            pt: 2,
            fontSize: '19px',
          }}
        >
          Received amount is less than Total due. Balance amount will be added
          to customer credit.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ paddingRight: '24px', p: 4, py: 3, gap: 2 }}>
        <Button
          onClick={handleClose}
          color="primary"
          sx={{
            width: 150,
            height: 42,
            borderRadius: '5px',
            fontWeight: 'bold',
          }}
          variant="outlined"
        >
          {t('sales:cancel')}
        </Button>
        <Button
          autoFocus
          color="primary"
          onClick={handlePayClick}
          sx={{
            width: 150,
            height: 42,
            borderRadius: '5px',
            fontWeight: 'bold',
          }}
          type="submit"
          variant="contained"
        >
          Pay
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AlertPopup1;

import { Loader } from '../components/Loader';
import React, { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import useUser from '../core/hooks/useUser';
import { Privileges } from '../core/types/commonType';
import AccessDenied from '../components/ModelPopup/AccessDenied';
import NotFound from '../components/ModelPopup/NotFound';
import RestaurantSales from '../page/ResturantSales';

const Dashboard = React.lazy(() => import('../page/Dashboard'));
const Category = React.lazy(() => import('../page/Category'));
const Product = React.lazy(() => import('../page/Product'));
const Sales = React.lazy(() => import('../page/Sales'));
const SalesDetails = React.lazy(() => import('../page/SalesDetails'));
const Purchase = React.lazy(() => import('../page/Purchase'));
const PurchaseList = React.lazy(() => import('../page/PurchaseList'));
const InventoryDetails = React.lazy(() => import('../page/InventoryDetails'));
const BillPayments = React.lazy(() => import('../page/BillPayments'));
const UserManagement = React.lazy(() => import('../page/UserManagement'));
const ReturnSales = React.lazy(() => import('../page/ReturnSales'));
const BranchManagement = React.lazy(() => import('../page/BranchManagement'));
const Company = React.lazy(() => import('../page/Company'));
const Fallback = React.lazy(() => import('../components/Fallback/Fallback'));
const SupplierManagement = React.lazy(
  () => import('../page/SupplierManagement')
);
const CustomerManagement = React.lazy(() => import('../page/Customer'));
const CustomerManageBalanceHistory = React.lazy(
  () => import('../page/CustomerHistory/index')
);

const ReturnSaleList = React.lazy(() => import('../page/ReturnSaleList'));
const BillHistory = React.lazy(() => import('../page/BillHistory'));
const Treatments = React.lazy(() => import('../page/Pharmacy/Treatments'));
const Dental = React.lazy(() => import('../page/Pharmacy/Dental'));

const Patients = React.lazy(() => import('../page/Pharmacy/Patients'));
const SalesReturnHistory = React.lazy(
  () => import('../page/SaleReturnHistory/index')
);
const DailyReports = React.lazy(() => import('../page/Reports/DailySummary'));
const MonthlyReports = React.lazy(
  () => import('../page/Reports/MonthlyReport')
);

const SmsManagement = React.lazy(() => import('../page/Message'));

const Machineries = React.lazy(() => import('../page/Machineries'));
const PendingRequest = React.lazy(() => import('../page/PendingRequest'));

const EmployeeRequestForm = React.lazy(() => import('../page/VehicleRequest'));
const AdminAcceptRequest = React.lazy(() => import('../page/RequestAccept'));

/**
 * AppRoutes will load the app routes.
 * @returns
 */
const Promotion = React.lazy(() => import('../page/Promotion'));
const Addresses = React.lazy(() => import('../page/Addresses'));

const AppRoutes = () => {
  const { userHasRight } = useUser();

  return (
    <Routes>
      <Route
        element={
          <Suspense fallback={<Loader />}>
            <Fallback />
          </Suspense>
        }
        path="/fallback"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.Dashboard) ||
            userHasRight(Privileges.DashboardTrendingProduct) ||
            userHasRight(Privileges.DashboardTodaySaleZone) ||
            userHasRight(Privileges.DashboardProductAlert) ||
            userHasRight(Privileges.DashboardCategoryZone) ||
            userHasRight(Privileges.DashboardWeeklySaleZone) ||
            userHasRight(Privileges.DashboardLoyality) ? (
              <Dashboard />
            ) : (
              <AccessDenied />
            )}
          </Suspense>
        }
        path="/dashboard"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.CategoryView) ? (
              <Category />
            ) : (
              <AccessDenied />
            )}
          </Suspense>
        }
        path="/category"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.RestaurantSales) ? (
              <RestaurantSales />
            ) : (
              <AccessDenied />
            )}
          </Suspense>
        }
        path="/restaurantSales"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.Construction) ? (
              <EmployeeRequestForm />
            ) : (
              <AccessDenied />
            )}
          </Suspense>
        }
        path="/employeeRequest-form"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.Construction) ? (
              <AdminAcceptRequest />
            ) : (
              <AccessDenied />
            )}
          </Suspense>
        }
        path="/adminAcceptRequest"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.ProductView) ? (
              <Product />
            ) : (
              <AccessDenied />
            )}
          </Suspense>
        }
        path="/product"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.Sales) ? <Sales /> : <AccessDenied />}
          </Suspense>
        }
        path="/sales"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.SalesList) ? (
              <SalesDetails />
            ) : (
              <AccessDenied />
            )}
          </Suspense>
        }
        path="/sales-list"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.Purchase) ? (
              <Purchase />
            ) : (
              <AccessDenied />
            )}
          </Suspense>
        }
        path="/purchase"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.PurchaseList) ? (
              <PurchaseList />
            ) : (
              <AccessDenied />
            )}
          </Suspense>
        }
        path="/purchase-list"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.Inventory) ? (
              <InventoryDetails />
            ) : (
              <AccessDenied />
            )}
          </Suspense>
        }
        path="/inventory-details"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.BillPayments) ? (
              <BillPayments />
            ) : (
              <AccessDenied />
            )}
          </Suspense>
        }
        path="/billPayments"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.UserManageView) ? (
              <UserManagement />
            ) : (
              <AccessDenied />
            )}
          </Suspense>
        }
        path="/user-management"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.SupplierManageView) ? (
              <SupplierManagement />
            ) : (
              <AccessDenied />
            )}
          </Suspense>
        }
        path="/Supplier-management"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.CompanyManageView) ? (
              <Company />
            ) : (
              <AccessDenied />
            )}
          </Suspense>
        }
        path="/company-management"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.BranchManageView) ? (
              <BranchManagement />
            ) : (
              <AccessDenied />
            )}
          </Suspense>
        }
        path="/branch-management"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.CustomerManageView) ? (
              <CustomerManagement />
            ) : (
              <AccessDenied />
            )}
          </Suspense>
        }
        path="/customer-management"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.CustomerManageView) ? (
              <SmsManagement />
            ) : (
              <AccessDenied />
            )}
          </Suspense>
        }
        path="/send-sms"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.Construction) ? (
              <Machineries />
            ) : (
              <AccessDenied />
            )}
          </Suspense>
        }
        path="/machineries-management"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.Construction) ? (
              <PendingRequest />
            ) : (
              <AccessDenied />
            )}
          </Suspense>
        }
        path="/pending-request"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.SalesReturn) ? (
              <ReturnSaleList />
            ) : (
              <AccessDenied />
            )}
          </Suspense>
        }
        path="/return-list"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.SalesReturn) ? (
              <ReturnSales />
            ) : (
              <AccessDenied />
            )}
          </Suspense>
        }
        // path="/return-sales:orderId"
        path="/return-sales"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.BillPayments) ? (
              <BillHistory />
            ) : (
              <AccessDenied />
            )}
          </Suspense>
        }
        path="/bill-history"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.PatientView) ? (
              <Treatments />
            ) : (
              <AccessDenied />
            )}
          </Suspense>
        }
        path="/pharmacy/treatments"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.PatientView) ? (
              <Patients />
            ) : (
              <AccessDenied />
            )}
          </Suspense>
        }
        path="/pharmacy/patients"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.CustomerManageView) ? (
              <CustomerManageBalanceHistory />
            ) : (
              <AccessDenied />
            )}
          </Suspense>
        }
        path="/customer-management-history/:id"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.SalesReturn) ? (
              <SalesReturnHistory />
            ) : (
              <AccessDenied />
            )}
          </Suspense>
        }
        path="/sales-return/:saleId"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.dentalTreatmentView) ? (
              <Dental />
            ) : (
              <AccessDenied />
            )}
          </Suspense>
        }
        path="/pharmacy/dental"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.SalesReturn) ? (
              <ReturnSales />
            ) : (
              <AccessDenied />
            )}
          </Suspense>
        }
        path="/return-sales/:orderId"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.ReportDailySummary) ? (
              <DailyReports />
            ) : (
              <AccessDenied />
            )}
          </Suspense>
        }
        path="/dailyReports"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.ReportMonthlySummary) ? (
              <MonthlyReports />
            ) : (
              <AccessDenied />
            )}
          </Suspense>
        }
        path="/monthlyReports"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.Dashboard) ? (
              <Promotion />
            ) : (
              <AccessDenied />
            )}
          </Suspense>
        }
        path="/promotion"
      />
      <Route
        element={
          <Suspense fallback={<Loader />}>
            {userHasRight(Privileges.Construction) ? (
              <Addresses />
            ) : (
              <AccessDenied />
            )}
          </Suspense>
        }
        path="/addresses"
      />
      <Route
        element={<NotFound />}
        path="*"
      />
      <Route
        element={<AccessDenied />}
        path="/access-denied"
      />
      <Route
        element={
          <Navigate
            replace
            to="/dashboard"
          />
        }
        path=""
      />
    </Routes>
  );
};

export default AppRoutes;

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import FieldHeader from '../../../components/FieldHeader';
import { theme } from '../../../theme';
import { useFormik } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IAddCount } from '../../../core/Interface/property.interface';
import { useAppDispatch } from '../../../store/hooks';
import { updateProductCount } from '../store/BasketSlice';

interface IAddCountProb {
  openModel?: boolean;
  title: string;
  handleCloseDialog: (close: boolean) => void;
  item?: IAddCount;
}

const AddItemCount = ({
  title,
  openModel = false,
  handleCloseDialog,
  maxDiscountAmount,
  item,
}: IAddCountProb & { maxDiscountAmount: number }) => {
  const { t } = useTranslation(['common']);
  const [open] = React.useState(openModel);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useAppDispatch();

  const handleClose = () => {
    handleCloseDialog(false);
  };

  const maxDiscount = useMemo(() => {
    return item?.unitPrice ?? 0;
  }, [item]);

  const formik = useFormik({
    initialValues: {
      count: item?.count,
      discount: item?.discountValue ?? 0,
      discountAsPercentage: item?.isDiscountPercentage ?? false,
      givenPrice:
      item?.unitPrice && item?.discountValue
        ? Math.max(item.unitPrice - item.discountValue, 0)
        : 0,
    isGivenPrice: !!item?.discountValue && !item?.isDiscountPercentage,
    },
    onSubmit: async (values) => {
      let discountValue = values.discount || 0;
      if (values.isGivenPrice && item?.unitPrice !== undefined) {
        discountValue = item.unitPrice - values.givenPrice;
      }

      if (discountValue >= 0 && item) {
        const itemCount: IAddCount = {
          code: item.code || '',
          count: values.count || 1,
          discountValue: discountValue,
          isDiscountPercentage: values.discountAsPercentage || false,
          id: item.id || '',
        };
        dispatch(updateProductCount(itemCount));
      }

      handleClose();
    },

    validate: (values) => {
      const errors: Partial<typeof values> = {};

      if (!values.isGivenPrice) {
        if (
          values.discount &&
          item?.unitPrice &&
          values.discount > (maxDiscountAmount / item?.unitPrice) * 100
        ) {
          errors.discount = maxDiscountAmount;
        }
      } else {
        if (
          values.givenPrice &&
          values.givenPrice < maxDiscount - maxDiscountAmount
        ) {
          errors.discount = maxDiscountAmount;
        }
      }

      return errors;
    },
  });

  const formValid = useMemo(() => {
    if (formik.values.isGivenPrice) {
      return (
        formik.values.count !== undefined &&
        formik.values.count > 0 &&
        !formik.errors.discount &&
        (item?.unitPrice !== undefined
          ? formik.values.givenPrice <= item.unitPrice
          : true)
      );
    } else {
      const discountAmount = formik.values.discountAsPercentage
        ? ((formik.values.discount ?? 0) / 100) * (item?.unitPrice ?? 0)
        : formik.values.discount || 0;
      return (
        formik.values.count !== undefined &&
        formik.values.count > 0 &&
        discountAmount <= maxDiscountAmount &&
        !formik.errors.discount &&
        discountAmount >= 0 &&
        item !== undefined
      );
    }
  }, [formik.values, formik.errors, maxDiscountAmount, item]);

  React.useEffect(() => {
    if (!formik.values.isGivenPrice) {
      formik.setFieldValue('givenPrice', 0, false);
    } else {
      formik.setFieldValue('discount', 0, false);
      formik.setFieldValue('discountAsPercentage', false, false);
    }

    if (!formik.values.discountAsPercentage) {
      formik.setFieldValue('discount', 0, false);
    }
  }, [
    formik.values.discountAsPercentage,
    formik.values.isGivenPrice,
    formik.values.givenPrice,
    item,
  ]);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Dialog
          aria-describedby="alert-dialog-description"
          classes={{ paper: 'inherit' }}
          fullScreen={isMobile}
          fullWidth={isMobile}
          maxWidth="lg"
          open={open}
        >
          <DialogTitle sx={{ fontWeight: 600, p: 4 }}>{title}</DialogTitle>
          <DialogContent sx={{ width: isMobile ? 'auto' : '40vw', p: 4 }}>
            {formValid ? null : (
              <Typography
                sx={{
                  color: 'error.main',
                  textAlign: 'center',
                  fontWeight: 700,
                }}
              >
                Please check your discount (Maximum discount:{' '}
                {maxDiscountAmount})
              </Typography>
            )}
            <Box>
              <Grid container>
                <GridItem>
                  <FieldHeader title={'Count'} />
                  <TextField
                    autoComplete="off"
                    placeholder="Count"
                    size="small"
                    {...formik.getFieldProps('count')}
                    InputProps={{ inputProps: { min: 1 } }}
                    sx={{ height: `${theme.spacing(5)}`, width: '100%' }}
                    type="number"
                  />
                </GridItem>

                <GridItem>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.isGivenPrice}
                        {...formik.getFieldProps('isGivenPrice')}
                        //disabled={formik.values.discountAsPercentage}
                      />
                    }
                    label="Are you going to use given Price"
                    sx={{ pt: 2 }}
                  />
                </GridItem>

                {formik.values.isGivenPrice && (
                  <GridItem
                    lg={6}
                    sm={6}
                    sx={{ pt: 2 }}
                    xs={6}
                  >
                    <FieldHeader title={'Discount Price'} />
                    <TextField
                      autoComplete="off"
                      placeholder="Given Price"
                      size="small"
                      {...formik.getFieldProps('givenPrice')}
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                      sx={{ height: `${theme.spacing(5)}`, width: '100%' }}
                      type="number"
                      //disabled={formik.values.discountAsPercentage}
                    />
                  </GridItem>
                )}

                {!formik.values.isGivenPrice && (
                  <>
                    <GridItem
                      lg={6}
                      sm={6}
                      sx={{ pt: 2 }}
                      xs={6}
                    >
                      <FieldHeader title={'Discount'} />
                      <TextField
                        autoComplete="off"
                        placeholder="Discount"
                        size="small"
                        {...formik.getFieldProps('discount')}
                        InputProps={{ inputProps: { min: 1 } }}
                        sx={{ height: `${theme.spacing(5)}`, width: '100%' }}
                        type="number"
                        //disabled={formik.values.isGivenPrice}
                      />
                    </GridItem>
                    <GridItem
                      lg={6}
                      sm={6}
                      xs={6}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.discountAsPercentage}
                            {...formik.getFieldProps('discountAsPercentage')}
                            //disabled={formik.values.isGivenPrice}
                          />
                        }
                        label="Is Percentage (%)"
                        sx={{ pt: 5, pl: 2 }}
                      />
                    </GridItem>
                  </>
                )}
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions sx={{ paddingRight: '24px', p: 4, py: 3, gap: 2 }}>
            <Button
              onClick={handleClose}
              sx={{ width: 200, height: 42 }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              autoFocus
              disabled={!formValid}
              onClick={() => {
                formik.handleSubmit();
              }}
              sx={{ width: 200, height: 42 }}
              type="submit"
              variant="contained"
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
};

export default React.memo(AddItemCount);

const GridItem = ({ ...rest }) => {
  return (
    <Grid
      item
      key={1}
      lg={12}
      sm={12}
      xs={12}
      {...rest}
    />
  );
};

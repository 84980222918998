import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Select,
  TextField,
  SelectChangeEvent,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import FieldHeader from '../../../components/FieldHeader';
import { useAppDispatch } from '../../../store/hooks';

interface IAlertPopup {
  open: boolean;
  handleCloseAlert: () => void;
  unitPrice: number;
  handleAdd: (calculatedValue: number | null, inputCount: number) => void;
}

const AlertPopup: React.FC<IAlertPopup> = ({
  open,
  handleCloseAlert,
  unitPrice,
  handleAdd,
}) => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();
  const [amount, setAmount] = useState<string>('');
  const [calculatedValue, setCalculatedValue] = useState<number | null>(null);

  const handleClose = () => {
    handleCloseAlert();
  };

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputCount = event.target.value;
    setAmount(inputCount);
    calculateValue(parseFloat(inputCount));
  };

  const calculateValue = (inputCount: number) => {
    const calculated = inputCount * unitPrice;
    setCalculatedValue(calculated);
  };

  const handleAddClick = () => {
    if (calculatedValue !== null) {
      handleAdd(calculatedValue, parseFloat(amount));
      handleClose();
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle
        sx={{
          background: '#CFD8DC',
          paddingRight: '24px',
          borderRadius: 1,
          p: 4,
          py: 2,
          gap: 2,
        }}
      >
        <strong> Enter Weight or Liters</strong>
      </DialogTitle>
      <DialogContent
        sx={{
          paddingRight: '24px',
          p: 4,
          py: 2,
          gap: 2,
        }}
      >
        <Grid
          container
          spacing={2}
          pt={2}
        >
          <Grid
            item
            xs={12}
          >
            <FieldHeader title=" Weight or Liters" />
            <TextField
              autoComplete="off"
              fullWidth
              placeholder="Enter Kg/L (1kg 500g = 1.5)"
              variant="outlined"
              value={amount}
              onChange={handleAmountChange}
              type="number"
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <FieldHeader title="Amount" />
            <TextField
              autoComplete="off"
              fullWidth
              value={calculatedValue ?? ''}
              variant="outlined"
              disabled
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ px: 4, py: 2, gap: 2 }}>
        <Button
          onClick={handleClose}
          color="primary"
          sx={{
            width: 150,
            height: 42,
            borderRadius: '5px',
            fontWeight: 'bold',
          }}
          variant="outlined"
        >
          {t('common:cancel')}
        </Button>
        <Button
          autoFocus
          color="primary"
          onClick={handleAddClick}
          sx={{
            width: 150,
            height: 42,
            borderRadius: '5px',
            fontWeight: 'bold',
          }}
          type="submit"
          variant="contained"
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertPopup;

import React, { useEffect, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Grid, TextField, useMediaQuery } from '@mui/material';
import FieldHeader from '../../../components/FieldHeader';
import { theme } from '../../../theme';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../store/hooks';
import { useSelector } from 'react-redux';
import { addCustomer } from '../store/BasketSelector';
import { addCustomerDetailes } from '../store/BasketSlice';
import { appColors } from '../../../theme/appColors';
import { IAddCustomer } from '../../../core/Interface/property.interface';
import { useNotifier } from '../../../core/Notifier';
import { useGetCustomerByMobileQuery } from '../../posApi';
import { ICustomerMobileDatails } from '../../../core/Interface/api.interface';
import Autocomplete from '@mui/material/Autocomplete'; // Import Autocomplete component

interface ICustomerMobileDetails {
  name: string;
  mobile: string;
  email: string;
  address: string;
  id: string;
  customers: string;
}
interface IMobile {
  title: string;
  openModel?: boolean;
  handleCloseDialog: (close: boolean) => void;
  customers: string;
}

const AddMobile: React.FC<IMobile> = ({
  title,
  openModel = false,
  handleCloseDialog,
}) => {
  const { t } = useTranslation(['common', 'sales']);
  const [open, setOpen] = useState(openModel);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useAppDispatch();
  const selectAddCustomer = useSelector(addCustomer);
  const { showErrorMessage } = useNotifier();
  const [customerNumber, setCustomerNumber] = useState<string>('');
  const [selectedCustomer, setSelectedCustomer] = useState<string>('');
  const [selectedCustomerMobile, setSelectedCustomerMobile] =
    useState<string>('');
  const [nameOptions, setNameOptions] = useState<string[]>([]);
  const [enableNameField, setEnableNameField] = useState(false);
  const [matchedMobileNumber, setMatchedMobileNumber] = useState<string>('');
  const [mobileOptions, setMobileOptions] = useState<string[]>([]);

  const {
    data: customerData,
    isFetching,
    refetch,
  } = useGetCustomerByMobileQuery(customerNumber, {
    skip: !customerNumber,
  });

  // useEffect(() => {
  //   if (customerNumber) {
  //     refetch();
  //   }
  // }, [customerNumber, refetch]);

  // useEffect(() => {
  //   if (customerData) {
  //     const customers = (
  //       customerData.data as { customers: ICustomerMobileDatails[] }
  //     )?.customers;
  //     if (customers) {
  //       setNameOptions(
  //         customers.map((customer: ICustomerMobileDatails) => customer.name)
  //       );
  //       // Enable name field if names are available
  //       if (customers.length > 0) {
  //         setEnableNameField(true);
  //       }
  //     }
  //   }
  // }, [customerData]);

  useEffect(() => {
    if (customerData) {
      const customers = (
        customerData.data as { customers: ICustomerMobileDatails[] }
      )?.customers;
      if (customers) {
        setMobileOptions(
          customers.map((customer: ICustomerMobileDatails) => customer.mobile)
        );
      }
    }
  }, [customerNumber, customerData]);

  // useEffect(() => {
  //   if (customerData) {
  //     const customers = customerData.customers;
  //     if (customers && customers.length > 0) {
  //       const matchedCustomer = customers.find(
  //         (customer: ICustomerMobileDatails) =>
  //           customer.mobile === customerNumber
  //       );
  //       if (matchedCustomer) {
  //         setMatchedMobileNumber(matchedCustomer.mobile);
  //         setSelectedCustomer(matchedCustomer.name);
  //       } else {
  //         setMatchedMobileNumber('');
  //         setSelectedCustomer('');
  //       }
  //     } else {
  //       setMatchedMobileNumber('');
  //       setSelectedCustomer('');
  //     }
  //   }
  // }, [customerData, customerNumber]);

  // const handleMobileNumberChange = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const { value } = event.target;

  //   setCustomerNumber(value);
  //   console.info(value);

  //   if (event) {
  //     formik.setFieldValue('mobile', value);
  //     // formik.setFieldValue(
  //     //   'customerName',
  //     //   value
  //     // );
  //     // setEnableNameField(true); // Enable name field if mobile number is entered
  //   }
  // };

  const handleMobileNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setCustomerNumber(value);
    console.info(value);

    if (event) {
      formik.setFieldValue('mobile', value);

      // Update the customerName field if the selected mobile number matches
      // any customer's mobile number in the customerData
      if (customerData) {
        const customers = (
          customerData.data as { customers: ICustomerMobileDatails[] }
        ).customers;

        const matchedCustomer = customers.find(
          (customer: ICustomerMobileDatails) => customer.mobile === value
        );

        if (matchedCustomer) {
          formik.setFieldValue('customerName', matchedCustomer.name);
        } else {
          formik.setFieldValue('customerName', ''); // Clear the customerName field if no match is found
          // {
          //   formik.getFieldMeta('customerName');
          // }
        }
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    handleCloseDialog(false);
  };

  // const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
  //   const selectedName = event.target.value as string;
  //   const selectedCustomerData = customerData?.customers.find(
  //     (customer: { name: string }) => customer.name === selectedName
  //   );
  //   if (selectedCustomerData) {
  //     setSelectedCustomer(selectedName);
  //     setSelectedCustomerMobile(selectedCustomerData.mobile);
  //   }
  // };

  const validateMobileNumber = (value: string) => {
    if (value.startsWith('0')) {
      const mobileRegex = /^[0-9]{10}$/;
      return mobileRegex.test(value);
    } else {
      const mobileRegex = /^[1-9]{9}$/;
      return mobileRegex.test(value);
    }
  };

  const formik = useFormik({
    initialValues: {
      mobile: selectAddCustomer.mobile,
      customerName: selectAddCustomer.customerName || '',
    },
    onSubmit: async (values) => {
      console.info(values);
      try {
        if (!validateMobileNumber(values.mobile || '')) {
          showErrorMessage('Mobile number should be valid.');
          return;
        }

        const selectCustomer: IAddCustomer = values;
        dispatch(addCustomerDetailes(selectCustomer));
        handleClose();
      } catch (error) {
        showErrorMessage('Something went wrong');
      }
    },
  });

  // const formValid = useMemo(() => {
  //   return (
  //     (formik.values.mobile !== undefined && formik.values.mobile.length > 9) ||
  //     (formik.values.customerName !== undefined &&
  //       formik.values.customerName.length > 0)
  //   );
  // }, [formik.values.mobile, formik.values.customerName]);

  // const formValid = useMemo(() => {
  //   return (
  //     (formik.values.mobile !== undefined && formik.values.mobile.length > 9)
  //     // (formik.values.customerName === '' || formik.values.customerName.length > 0)
  //   );
  // }, [formik.values.mobile, formik.values.customerName]);

  const formValid = useMemo(() => {
    return (
      formik.values.mobile !== undefined &&
      formik.values.mobile.trim().length > 0
    );
  }, [formik.values.mobile]);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Dialog
          aria-describedby="alert-dialog-description"
          classes={{ paper: 'inherit' }}
          fullScreen={isMobile}
          fullWidth={isMobile}
          maxWidth="lg"
          onClose={handleClose}
          open={open}
        >
          <DialogTitle
            sx={{
              fontWeight: 600,
              p: 3,
              backgroundColor: appColors.blueGreyishLight[30],
              borderRadius: '5px',
            }}
          >
            {title}
          </DialogTitle>
          <DialogContent
            sx={{ width: isMobile ? 'auto' : '40vw', p: 4, marginTop: '20px' }}
          >
            <Box>
              {/* {JSON.stringify(customerData || {})} */}
              <Grid container>
                <Grid
                  item
                  xs={12}
                >
                  <FieldHeader title={t('sales:mobileNumber')} />

                  <Autocomplete
                    // disablePortal
                    freeSolo
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.title;
                    }}
                    loading={isFetching} // Show loading only when options are being fetched
                    noOptionsText="Cant find number"
                    options={mobileOptions}
                    renderInput={(params) => (
                      <TextField
                        autoComplete="off"
                        {...params}
                        placeholder="Mobile Number"
                        // inputProps={{ maxLength: 10 }}
                        onChange={handleMobileNumberChange}
                        // type="number"
                      />
                    )}
                    renderOption={(props, option) => {
                      return (
                        <li {...props}>
                          <Grid
                            alignItems="center"
                            container
                          >
                            <Grid
                              item
                              onClick={(val) => {
                                if (customerData) {
                                  const customers = (
                                    customerData.data as {
                                      customers: ICustomerMobileDatails[];
                                    }
                                  ).customers;

                                  customers.map((data) => {
                                    if (data.mobile === option) {
                                      formik.setFieldValue('mobile', option);
                                      formik.setFieldValue(
                                        'customerName',
                                        data.name
                                      );
                                    }
                                  });
                                }
                              }}
                              sx={{ display: 'flex', width: 44 }}
                            >
                              {option}
                            </Grid>
                          </Grid>
                        </li>
                      );
                    }}
                    sx={{ width: '100&' }}
                    {...formik.getFieldMeta('mobile')}
                  />
                </Grid>
                <Grid
                  item
                  sx={{ pt: 2 }}
                  xs={12}
                >
                  <FieldHeader title={t('sales:customername')} />

                  <TextField
                    autoComplete="off"
                    fullWidth
                    onChange={formik.handleChange}
                    name="customerName" // Add name attribute for proper formik binding
                    value={formik.values.customerName} // Bind value to formik's customerName field
                    placeholder="Customer Name"
                    variant="outlined"
                    // {...formik.getFieldMeta('customerName')}
                  />

                  {/* <TextField
                    fullWidth
                    onChange={formik.handleChange}
                    name="customerName"
                    value={formik.values.customerName}
                    placeholder="Customer Name"
                    variant="outlined"
                    error={Boolean(formik.errors.customerName)} // Set error to true if there's an error
                    helperText={formik.errors.customerName} // Display error message if present
                    disabled={!formik.values.mobile}
                    // {...formik.getFieldMeta('customerName')}
                  /> */}
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions sx={{ paddingRight: '24px', p: 4, py: 3, gap: 2 }}>
            <Button
              onClick={handleClose}
              sx={{
                width: 200,
                height: 42,
                borderRadius: '5px',
              }}
              variant="outlined"
            >
              {t('sales:cancel')}
            </Button>
            <Button
              autoFocus
              disabled={!formValid}
              sx={{
                width: 200,
                height: 42,
                borderRadius: '5px',
              }}
              onClick={(val) => {
                const selectCustomer: IAddCustomer = formik.values;
                dispatch(addCustomerDetailes(selectCustomer));
                handleClose();
              }}
              type="submit" // Submit the form when this button is clicked
              variant="contained"
            >
              {t('sales:add')}
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
};

export default React.memo(AddMobile);

// eslint-disable-next-line no-unused-vars, react/no-multi-comp
const GridItem = ({ ...rest }) => {
  return (
    <Grid
      item
      key={1}
      lg={12}
      sm={12}
      xs={12}
      {...rest}
    />
  );
};

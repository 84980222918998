import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IProductData, SalesItem } from '../../../core/Interface/api.interface';
import {
  IAddCount,
  IAddCustomer,
  IBasketState,
  IDiscount,
} from '../../../core/Interface/property.interface';

export interface BasketItem extends IProductData {
  count: number;
}

export interface BasketState {
  items: BasketItem[];
  discount?: number;
  discountAsPercentage?: boolean;
  finalSummaryStare?: boolean;
  mobile?: string;
  receivedAmount?: number;
  returnAmount?: number;
  customerName?: string;
  calculatedValue?: number;
  paymentMethod: string;
}

const initialState: BasketState = {
  items: [],
  discount: 0,
  discountAsPercentage: false,
  finalSummaryStare: false,
  returnAmount: 0,
  paymentMethod: ' ',
};

export const basket = createSlice({
  name: 'basketForRestaurant',
  initialState,
  reducers: {
    addItem: (state, action: PayloadAction<BasketItem>) => {
      let temItem = { ...action.payload };
      const findItem = state.items.find((element) => element.id === temItem.id);
      if (findItem) {
        if (temItem.isKgOrLitre) {
          const existingItem = state.items
            .map((item) => {
              if (item.id === temItem.id) {
                return { ...item, count: temItem.count };
              }
              return item;
            })
            .sort((a, b) =>
              a.id === temItem.id ? 1 : b.id === temItem.id ? -1 : 0
            );
          state.items = existingItem;
        } else {
          const existingItem = state.items
            .map((item) => {
              if (item.id === temItem.id) {
                return { ...item, count: item.count + 1 };
              }
              return item;
            })
            .sort((a, b) =>
              a.id === temItem.id ? 1 : b.id === temItem.id ? -1 : 0
            );
          state.items = existingItem;
        }
      } else {
        state.items.push(temItem);
      }
    },
    removeItem: (state, action: PayloadAction<string>) => {
      const findItemIndex = state.items.findIndex(
        (element) => element.id === action.payload
      );
      if (findItemIndex > -1) {
        state = { ...state, items: state.items.splice(findItemIndex, 1) };
      }
    },
    removeBasket: (state) => {
      state = { ...initialState };
      return state;
    },
    updateProductCount: (state, action: PayloadAction<IAddCount>) => {
      const { id, count, discountValue, isDiscountPercentage } = action.payload;
      const findItemIndex = state.items.findIndex(
        (element) => element.id === id
      );
      state.items[findItemIndex].count = count;
      state.items[findItemIndex].defaultDiscountAmount = discountValue;
      state.items[findItemIndex].isDiscountPercentage = isDiscountPercentage;
    },

    addDiscount: (state, action: PayloadAction<IDiscount>) => {
      state.discount = action.payload.discount;
      state.discountAsPercentage = action.payload.discountAsPercentage;
    },
    addFinalSummaryState: (state, action: PayloadAction<IBasketState>) => {
      state.finalSummaryStare = action.payload.finalSummaryStare;
    },
    addCustomerDetailes: (state, action: PayloadAction<IAddCustomer>) => {
      state.mobile = action.payload.mobile;
      state.customerName = action.payload.customerName;
    },
    addReceivedMoney: (state, action: PayloadAction<number>) => {
      state.receivedAmount = action.payload;
    },
    addReturnAmount: (state, action: PayloadAction<number>) => {
      state.returnAmount = action.payload;
    },
    addPaymentMethod(state, action: PayloadAction<string>) {
      state.paymentMethod = action.payload;
    },
  },
});

export const {
  addItem,
  removeItem,
  removeBasket,
  updateProductCount,
  addDiscount,
  addFinalSummaryState,
  addCustomerDetailes,
  addReceivedMoney,
  addReturnAmount,
  addPaymentMethod,
} = basket.actions;

export const RestaurantBasketReducer = basket.reducer;

export { initialState as BasketInitialState };

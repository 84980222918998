import { Box, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { appColors } from '../../../theme/appColors';
import {
  getPriceFormat,
  getPriceFormatWithoutCurrency,
} from '../../../core/utils';
import { useTranslation } from 'react-i18next';

const Summary = ({
  subtotal,
  discount,
  total,
  currencyCode,
}: {
  subtotal: number;
  discount: number;
  total: number;
  currencyCode: string;
}) => {
  const { t } = useTranslation(['sales']);
  return (
    <Box
      sx={{
        // pb: 2,
        pt: 2,
        backgroundColor: appColors.blueGreyishLight[20],
        width: '100%',
        borderRadius: '5px',
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        spacing={2}
        sx={{ px: '25px', pt: '5px' }}
      >
        <LeftGrid text={t('sales:subtotal')} />
        <RightGrid
          amount={subtotal}
          currencyCode={currencyCode}
        />
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        spacing={2}
        sx={{ px: '25px', py: '5px' }}
      >
        <LeftGrid text={t('sales:discount')} />
        <RightGrid
          amount={discount}
          currencyCode={currencyCode}
        />
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        spacing={2}
        sx={{ px: '25px', py: '5px', pb: '5px' }}
      >
        <LeftGrid text={t('sales:total')} />
        <RightGrid
          amount={total}
          color={appColors.carmineRed[30]}
          isTotal
          currencyCode={currencyCode}
        />
      </Grid>
    </Box>
  );
};

export default React.memo(Summary);

// eslint-disable-next-line react/no-multi-comp
const RightGrid = ({
  amount,
  color,
  isTotal,
  currencyCode,
  ...rest
}: {
  amount: number;
  color?: string;
  isTotal?: boolean;
  currencyCode: string;
}) => {
  return (
    <Grid
      container
      display="flex"
      flexDirection="row"
      item
      justifyContent="flex-end"
      xs={6}
      {...rest}
    >
      <Typography
        sx={{
          fontWeight: 600,
          pb: 1,
          color: color || 'inherit',
          fontSize: isTotal ? '1.5rem' : '1.2rem',
        }}
        variant="h6"
      >
        {isTotal
          ? getPriceFormat(amount, currencyCode)
          : getPriceFormatWithoutCurrency(amount)}
      </Typography>
    </Grid>
  );
};

// eslint-disable-next-line react/no-multi-comp
const LeftGrid = ({ text }: { text: string }) => {
  return (
    <Grid
      item
      xs={6}
    >
      <Typography
        sx={{ fontWeight: 600 }}
        variant="h6"
      >
        {text}
      </Typography>
    </Grid>
  );
};
